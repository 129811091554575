import {useAccountType} from 'app/hooks/useAccountType';
import {getFullName} from 'app/utils/helpers/DataFormatHelper';

export const useGetColumns = (isEngagement) => {
  const {isClient} = useAccountType();

  return [
    {
      title: isEngagement ? 'Engagement ID' : 'Placement ID',
      dataIndex: 'engagement',
      key: 'engagement',
      render: (_, {engagement, placement}) =>
        isEngagement ? engagement.hid : placement.hid,
      width: 200,
    },
    {
      title: isEngagement ? 'Resource Name' : 'Candidate Name',
      dataIndex: 'resource',
      key: 'resource',
      render: (_, {resource, applicant}) => getFullName(resource || applicant),
      width: 200,
    },
    {
      title: isClient ? 'Supplier' : 'Client',
      dataIndex: 'user',
      key: 'user',
      render: (_, {client, supplier}) => {
        const user = isClient ? supplier : client;
        return user?.name;
      },
      width: 200,
    },
  ];
};
