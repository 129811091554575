import {
  capitalizedAllWords,
  capitalizedString,
} from '@wieldy/utils/helpers/StringHelper';

import {DATE_FORMATS} from '../config/app';
import {
  COUNTRIES,
  JOB_STATUSES,
  TRAVEL_REQUIREMENTS,
  WORK_TYPES,
  WORKSITES,
} from '../constants';
import {
  formatAmount,
  formatDate,
  formatMinutes,
  getDuration,
} from '../utils/helpers/DataFormatHelper';
import BaseModal from './BaseModal';
import ClientModal from './ClientModal';
import RequisitionModal from './RequisitionModal';
import ResourceModal from './ResourceModal';
import SupplierModal from './SupplierModal';

class EngagementModal extends BaseModal {
  constructor(engagement = {}, isSupplier = true) {
    super();
    this.id = engagement?.id;
    this.hid = engagement?.hid;
    this.requisition = new RequisitionModal(engagement?.requisition);
    this.resource = new ResourceModal(engagement.resource);
    this.supplier = new SupplierModal(
      this.buildExpandSupplier(engagement, true),
    );
    this.client = new ClientModal(this.buildExpandClient(engagement, true));
    this.user = isSupplier ? this.client : this.supplier;
    this.name = this.user.name;
    this.created_at = this.formatDate(engagement?.created_at, DATE_FORMATS.md);
    this.updated_at = this.formatDate(engagement?.updated_at, DATE_FORMATS.lg);
    this.updated_relative = this.formatRelativeTime(engagement?.updated_at);
    this.status = engagement.status;
    this.statusName = this.getStatus(engagement.status);
    this.isOpen = this.status !== 'completed';
    this.type = WORK_TYPES[engagement.type] || 'contract';
    //JOB INFO START ----->
    this.job_status = JOB_STATUSES[engagement?.job_status] || 'N/A';
    this.job_duration = getDuration(engagement.job_duration);
    this.job_worksite =
      WORKSITES[engagement.job_worksite] ||
      capitalizedString(engagement.job_worksite);
    this.job_worksite_location = COUNTRIES[engagement.job_worksite_location];
    this.job_travel_requirements =
      TRAVEL_REQUIREMENTS[engagement.job_travel_requirements] || 'N/A';
    this.job_flsa_eligible = this.getYesNo(engagement.job_flsa_eligible);
    this.job_overtime_eligible = this.getYesNo(
      engagement.job_overtime_eligible,
    );
    this.job_expenses_eligible = this.getYesNo(
      engagement.job_expenses_eligible,
    );
    this.job_overtime_multiple = engagement?.job_overtime_multiple?.toFixed(2);
    this.tax_engagement = this.getYesNo(engagement.tax_engagement);
    this.tax_expenses = this.getYesNo(engagement.tax_expenses);
    this.tax_labor = this.getYesNo(engagement.tax_labor);
    this.tax_country = engagement.tax_country
      ? `${engagement.tax_country}%`
      : 'N/A';
    this.tax_state = engagement.tax_state ? `${engagement.tax_state}%` : 'N/A';
    this.tax_city = engagement.tax_city ? `${engagement.tax_city}%` : 'N/A';
    this.job_payment_term = engagement.job_payment_term
      ? capitalizedAllWords(engagement.job_payment_term.replace('_', ' '))
      : 'N/A';
    //JOB INFO END <-----
    //INVOICE INFO START ------>
    this.total_reported_hours = formatMinutes(
      engagement.invoice_total_reported_minutes,
    );
    this.invoice_ajusted_hours = formatMinutes(
      engagement.invoice_adjusted_minutes,
    );
    this.invoice_hours = formatMinutes(engagement.invoice_minutes);
    this.invoice_total_time = formatAmount(engagement.invoice_total_time);
    this.invoice_total_reported_expenses = formatAmount(
      engagement.invoice_total_reported_expenses,
    );
    this.invoice_adjusted_expenses = formatAmount(
      engagement.invoice_adjusted_expenses,
    );
    this.invoice_expenses = formatAmount(engagement.invoice_expenses);
    this.invoice_total_spend = formatAmount(engagement.invoice_total_spend);
    this.invoice_total_adjustment = formatAmount(
      engagement.invoice_total_adjustment,
    );
    this.invoice_total_net_amount = formatAmount(
      engagement.invoice_total_net_amount,
    );
    //INVOICE INFO END <------
    this.start_date = formatDate(engagement.start_date, DATE_FORMATS.md);
    this.end_date = formatDate(engagement.end_date, DATE_FORMATS.md);
    this.original_end_date = formatDate(
      engagement.original_end_date,
      DATE_FORMATS.md,
    );

    this.hiring_manager = this.getUser(engagement.hiring_manager);
    this.account_manager = this.getUser(engagement.account_manager);
    this.resource_manager = this.getUser(engagement.resource_manager);
    this.resource_user = engagement.resource_user || null;

    this.part_time = engagement?.part_time;
    this.partTime = this.part_time ? 'Yes' : 'No';
    this.jobLocation = this.getLocation(engagement);
  }
}

export default EngagementModal;
