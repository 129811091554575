import {Checkbox} from 'antd';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {Controller, useFormContext, useWatch} from 'react-hook-form';

import AppInput from '../controles/input';
import AppRangePicker from '../controles/range-picker';

const CheckCollapse = ({name, label, ...props}) => {
  const {control, reset, getValues} = useFormContext();
  const isChecked = useWatch({
    control,
    name: `diversity_statuses.${name}.check`,
  });

  useEffect(() => {
    if (!isChecked) {
      reset({
        ...getValues(),
        diversity_statuses: {
          ...getValues('diversity_statuses'),
          [name]: {
            check: false,
            values: {
              granting_authority: '',
              certificate_number: '',
              dates_of_certification: [null, null],
            },
          },
        },
      });
    }
  }, [isChecked, name, reset, getValues]);

  return (
    <div style={{width: '100%'}}>
      <Controller
        name={`diversity_statuses.${name}.check`}
        control={control}
        render={({field}) => (
          <Checkbox {...field} {...props} checked={field.value}>
            {label}
          </Checkbox>
        )}
      />
      {isChecked && (
        <div className='ml-5 border-y my-2 py-2'>
          <AppInput
            isRequired
            name={`diversity_statuses.${name}.values.granting_authority`}
            label='Granting Authority'
            labelPosition='left'
            placeholder='Enter name of granting authority'
            style={{
              width: '100%',
            }}
          />
          <AppInput
            isRequired
            name={`diversity_statuses.${name}.values.certificate_number`}
            label='Certificate #'
            labelPosition='left'
            placeholder='Enter certificate number'
            style={{
              width: '100%',
            }}
          />
          <AppRangePicker
            isRequired
            name={`diversity_statuses.${name}.values.dates_of_certification`}
            label='Dates of Certification'
            labelPosition='left'
            style={{
              width: '100%',
            }}
          />
        </div>
      )}
    </div>
  );
};

CheckCollapse.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default CheckCollapse;
