import {INDUSTRIES_MAPPED} from '@register-app/constants/industries-data';
import {capitalizeFirstLetter} from 'app/utils/helpers/Capitalize';

import {CLIENT_RELATIONSHIP_STATUSES} from '../constants';
import CompanyModal from './CompanyModal';

class ClientModal extends CompanyModal {
  constructor(client) {
    super(client);
    this.locationProfileVisibility = this.getCountries(
      client?.location_profile_visibility,
    );
    this.locationSubmissionsPreference = this.getCountries(
      client?.location_submissions_preference,
    );
    this.receiveSubmission = this.getReceiveSubmission(
      client?.receive_submission,
    );
    this.browsingVisible = this.getProfileVisibility(client?.browsing_visible);
    this.industry = client?.industry
      ? INDUSTRIES_MAPPED[client?.industry]
      : 'N/A';
    this.relationStatus = client?.relation_status
      ? CLIENT_RELATIONSHIP_STATUSES[client?.relation_status]
      : null;
    this.activeSuppliers = client?.active_suppliers ?? 0;
    this.openRequisitions = client?.open_requisitions ?? 0;
    this.deployedResouces = client?.engaged_resources ?? 0;
    this.invoicing_period = client?.invoicing_period || null;
    this.insurance_required =
      client?.insurance_required || client?.has_insurance || false;
    this.billingAddress = {
      ...this.billingAddress,
      invoicingPeriod: capitalizeFirstLetter(this.invoicing_period),
    };
  }

  getProfileVisibility(visibility) {
    if (visibility === false && this.locationProfileVisibility.length > 0) {
      return 'Visible to some suppliers';
    } else if (
      visibility === false &&
      this.locationProfileVisibility.length === 0
    ) {
      return 'Not visible';
    } else {
      return 'Visible to all suppliers';
    }
  }

  getReceiveSubmission(allowed) {
    if (allowed === false && this.locationSubmissionsPreference.length > 0) {
      return 'Allowed from some suppliers';
    } else if (
      allowed === false &&
      this.locationSubmissionsPreference.length === 0
    ) {
      return 'Not allowed';
    } else {
      return 'Allowed from all suppliers';
    }
  }
}

export default ClientModal;
