import AppCard from '@wieldy/components/AppCard';
import AppRevealCard from '@wieldy/components/AppRevealCard';
import {Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {getUserName} from 'app/utils/helpers/ConvertHID';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {RiFileTransferLine, RiQuestionnaireLine} from 'react-icons/ri';

import {ClientCard} from '../../client';
import {SupplierCard} from '../../supplier';
import CandidatesCarousel from '../partials/CandidatesCarousel';
import RevealCardFooter from '../partials/RevealCardFooter';
import RequisitionCardDetail from '../RequisitionCardDetail';
import InquirySubmissionCardHeader from './InquirySubmissionCardHeader';

const grey = '#E7E7E7';
const yellow = '#FBDA14';
const green = '#D7FFD7';

const mapCardHeaderColor = {
  sent: green,
  in_review: yellow,
  responded: yellow,
  withdrawn: grey,
  closed: grey,
  engaged: grey,
  declined: grey,
};

const InquirySubmissionCard = ({item, type = 'inquiry'}) => {
  const [revealed, setReveal] = useState(false);
  const {isClient, isSupplier} = useAccountType();
  const isSubmission = type === 'submission';
  const isJobBoardSubmission = isSubmission && item?.created_from_job_board;

  useEffect(() => {
    if (item) {
      setReveal(false);
    }
  }, [item]);

  const headStyle = useMemo(
    () => ({
      backgroundColor: mapCardHeaderColor[item?.status_key],
      borderBottom: 'none',
    }),
    [item],
  );

  const user = isClient ? item?.supplier : item?.client;
  const link = isClient
    ? `/suppliers/${item?.supplier.id}/view-supplier`
    : `/clients/${item?.client.id}/view-client`;

  const userType = isClient ? 'Supplier' : 'Client';
  const prefix = isClient ? 'SUP-' : 'CLI-';

  const clientName = getUserName(user?.name, prefix, userType);

  return (
    <AppRevealCard
      title={`${userType} Details`}
      revealed={revealed}
      setReveal={setReveal}
      onClose={() => setReveal(false)}
      revealComponent={
        isSupplier ? (
          <ClientCard client={item?.client} />
        ) : (
          <SupplierCard supplier={item.supplier} />
        )
      }>
      <AppCard
        style={{height: '100%'}}
        className='card-header-small'
        title={
          <Typography.Text className='font-medium text-uppercase text-xs'>
            {!isSubmission ? (
              <RiQuestionnaireLine
                fontSize={18}
                className='mr-2 text-secondary'
              />
            ) : (
              <RiFileTransferLine
                fontSize={18}
                className='text-secondary mr-2'
              />
            )}
            {`${isSubmission ? 'Submission' : 'Inquiry'} #${item.hid}`}
          </Typography.Text>
        }
        extra={
          <Typography.Text className='text-xs font-semibold'>
            {item.status}
          </Typography.Text>
        }
        headStyle={headStyle}>
        <InquirySubmissionCardHeader item={item} isSubmission={isSubmission} />
        <CandidatesCarousel candidates={item?.candidates} />
        {item?.requisition && (
          <RequisitionCardDetail requisition={item.requisition} />
        )}
        <RevealCardFooter
          link={link}
          subTitle={userType}
          title={clientName}
          rating={user.ratingTotal}
          reviews={user.ratingCount}
          onClick={() => setReveal(true)}
          hideExpand={isJobBoardSubmission}
        />
      </AppCard>
    </AppRevealCard>
  );
};

InquirySubmissionCard.propTypes = {
  item: PropTypes.object,
  type: PropTypes.oneOf(['inquiry', 'submission']),
};

export default InquirySubmissionCard;
