import {Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

import InformationForm from './InformationForm';
import InformationView from './InformationView';

const RecruitmentTerms = ({requisitionModal}) => {
  const {currentStep, nextStep, setStepData, stepData} = useStep();
  const {
    base_salary,
    base_salary_min,
    base_salary_max,
    billing_rate,
    billing_rate_min,
    billing_rate_max,
    isTemporary,
    isContract,
    isC2C,
  } = requisitionModal;

  const init = useMemo(() => {
    const isBillingRage = isTemporary || (isContract && !isC2C);
    return {
      base: isBillingRage ? billing_rate * 2080 : base_salary,
      min: isBillingRage ? billing_rate_min * 2080 : base_salary_min,
      max: isBillingRage ? billing_rate_max * 2080 : base_salary_max,
    };
  }, [
    base_salary,
    base_salary_max,
    base_salary_min,
    billing_rate,
    billing_rate_max,
    billing_rate_min,
    isC2C,
    isContract,
    isTemporary,
  ]);

  return (
    <React.Fragment>
      <Typography.Paragraph>
        Specify the recruitment terms is case a successful placement is made
      </Typography.Paragraph>

      {currentStep > 5 && stepData ? (
        <InformationView requisitionModal={requisitionModal} />
      ) : (
        currentStep === 5 && (
          <InformationForm
            initialValues={{
              ...init,
              recruitment_fee_structure: stepData?.recruitment_fee_structure,
              recruitment_fee: stepData?.recruitment_fee,
              guarantee_period: stepData?.guarantee_period,
              guarantee_period_action: stepData?.guarantee_period_action,
              start_date: stepData?.start_date,
              end_date: stepData?.end_date,
              isFeeContract: isContract && !isC2C,
            }}
            onSave={setStepData}
            onContinue={nextStep}
          />
        )
      )}
    </React.Fragment>
  );
};

RecruitmentTerms.propTypes = {
  requisitionModal: PropTypes.object,
};
export default React.memo(RecruitmentTerms);
