import {requisitionAPI} from 'app/api/requisitionApi';
import axios from 'app/config/axios';
import useReactQuery, {
  useQueryClient,
  useReactMutation,
} from 'app/hooks/useReactQuery';
import {useInfiniteQuery} from 'react-query';

export const useCreateRequisition = () => {
  const client = useQueryClient();
  return useReactMutation(async (postdata) => {
    const {data} = await axios.post('requisitions', postdata);
    await client.invalidateQueries();
    return {
      data,
      message: 'Requisition created successfully',
    };
  });
};

/**
 * Get requisitions by different parameters
 */

export const useGetRequisitions = (params = {}, options = {}) => {
  return useReactQuery(
    ['requisitions', params],
    async () => {
      const {data} = await axios.get('requisitions', {params});
      return {
        requisitions: data?.data ?? [],
        total_count: data?.total_count ?? 0,
      };
    },
    options,
  );
};

export const useGetInfinityRequisitions = (params) => {
  return useInfiniteQuery(
    ['requisitions', params],
    async ({pageParam = 1}) => {
      const res = await axios.get('requisitions', {
        params: {...params, page: pageParam - 1},
      });
      return res.data.data;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length === params.limit
          ? allPages.length + 1
          : undefined;
      },
    },
  );
};

export const useGetAllRequisitions = (
  params = {},
  statuses = [],
  isSupplier,
) => {
  return useReactQuery(
    ['requisitions', params],
    async () => {
      const allPromise = statuses.map(({status, sort}) => {
        const rest = isSupplier ? {relation_status: status} : {status};
        return requisitionAPI.getRequisitions({...params, ...rest, sort});
      });
      const response = await Promise.all(allPromise);
      return response.map(({data, config}) => ({
        requisitions: data?.data || [],
        total_count: data?.total_count || 0,
        status: isSupplier
          ? config.params.relation_status
          : config.params.status,
      }));
    },
    {
      enabled: !!statuses.length,
    },
  );
};

/**
 * Get single detail of requisition
 */
export const useGetRequisition = (requisitionId, queryParams = {}) => {
  return useReactQuery(
    ['requisition', requisitionId, queryParams],
    async ({meta}) => {
      const {data} = await axios.get('requisitions/' + requisitionId, {
        params: meta,
      });
      return data;
    },
    {meta: queryParams, enabled: !!requisitionId},
  );
};

/**
 * Update requisition
 */
export const useUpdateRequisition = (requisitionId) => {
  const client = useQueryClient();
  return useReactMutation(async (payload) => {
    const {data} = await axios.patch('requisitions/' + requisitionId, payload);
    await client.invalidateQueries(['requisition', requisitionId]);
    return data;
  });
};

/**
 * Add requisition bookmark by supplier
 */
export const useAddBookmarkToRequisition = (id, singlePage = false) => {
  const client = useQueryClient();
  return useReactMutation(async (isBookmarked) => {
    const payload = {requisition: id};
    let message;
    if (isBookmarked === false) {
      await axios.post('requisition_bookmarks', payload);
      message = 'Requisition bookmarked successfully';
    } else {
      await axios.delete('requisition_bookmarks', {data: payload});
      message = 'Requisition removed bookmark successfully';
    }
    const queryKey = singlePage ? ['requisition', id] : ['requisitions'];
    await client.invalidateQueries(queryKey);
    return {message};
  });
};
