import AppCard from '@wieldy/components/AppCard';
import {Typography} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';

import {DATE_FORMATS} from '../../../../../../config/app';
import {
  GUARANTEE_PERIOD,
  GUARANTEE_PERIOD_ACTION,
  RECRUITMENT_FEE_STRUCTURE,
} from '../../../../../../constants';
import {WORK_TYPE_KEYS} from '../../../../../../constants/work-types';
import {useAccountType} from '../../../../../../hooks/useAccountType';
import {useCalcFee} from '../../../../../../hooks/useCalcFee';
import {useRole} from '../../../../../../hooks/useRole';
import {
  formatBillingRateRage,
  getProcessedDate,
} from '../../../../../../utils/helpers/DataFormatHelper';

const PlacementDetailsView = () => {
  const {details, requisition} = useSelector(({placement}) => placement);
  const {isMarketplace} = useAccountType();
  const {isMarketManager, isAdmin} = useRole();
  const isShowButton = isMarketplace && (isMarketManager || isAdmin);

  const isContract = requisition.type === WORK_TYPE_KEYS.contract;
  const isTemporary = requisition.type === WORK_TYPE_KEYS.temporary;
  const baseKey = isContract || isTemporary ? 'billing_rate' : 'base_salary';

  const startDate = getProcessedDate(details?.start_date);
  const endDate = getProcessedDate(details?.end_date);

  const {isFixed, fee} = useCalcFee({
    ...details,
    isTemporary,
    isContract,
    isC2C: false,
    start_date: moment(details?.start_date).unix(),
    end_date: details?.end_date ? moment(details?.end_date).unix() : null,
  });

  return (
    <AppCard style={{maxWidth: 620}}>
      {isShowButton && (
        <>
          <div className={'mb-5'}>
            <Typography.Title level={4}>Hiring Manager</Typography.Title>
            <Typography.Paragraph>
              {details.hiring_manager
                ? `${details.hiring_manager?.first_name || ''} ${
                    details.hiring_manager?.last_name || ''
                  }`
                : 'N/A'}
            </Typography.Paragraph>
          </div>
          <div className={'mb-5'}>
            <Typography.Title level={4}>Supplier Contact</Typography.Title>
            <Typography.Paragraph>
              {details.resource_manager?.first_name +
                ' ' +
                details.resource_manager?.last_name}
            </Typography.Paragraph>
          </div>
          <div className={'mb-5'}>
            <Typography.Title level={4}>Account Manager</Typography.Title>
            <Typography.Paragraph>
              {details.account_manager?.first_name +
                ' ' +
                details.account_manager?.last_name}
            </Typography.Paragraph>
          </div>
        </>
      )}
      <div className={'mb-5'}>
        <Typography.Title level={4}>
          {isContract || isTemporary
            ? 'Hourly Rate Offer'
            : 'Base Salary Offer'}
        </Typography.Title>
        <Typography.Paragraph>
          {formatBillingRateRage(details?.[baseKey], null, null)}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>
          {isContract ? 'Start Date' : 'Job Start Date'}
        </Typography.Title>
        <Typography.Paragraph>
          {startDate?.format(DATE_FORMATS.md)}
        </Typography.Paragraph>
      </div>
      {isContract && endDate && (
        <div className={'mb-5'}>
          <Typography.Title level={4}>End Date</Typography.Title>
          <Typography.Paragraph>
            {endDate.format(DATE_FORMATS.md)}
          </Typography.Paragraph>
        </div>
      )}
      <div className={'mb-5'}>
        <Typography.Title level={4}>Guarantee Period</Typography.Title>
        <Typography.Paragraph>
          {GUARANTEE_PERIOD[details.guarantee_period]}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Guarantee Action</Typography.Title>
        <Typography.Paragraph>
          {GUARANTEE_PERIOD_ACTION[details.guarantee_period_action]}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Recruitment Fee Structure</Typography.Title>
        <Typography.Paragraph>
          {isFixed
            ? RECRUITMENT_FEE_STRUCTURE.fixed_fee
            : `${details.recruitment_fee}% of salary`}
        </Typography.Paragraph>
      </div>
      <div className={'mb-5'}>
        <Typography.Title level={4}>Recruitment Fee</Typography.Title>
        <Typography.Paragraph>{fee}</Typography.Paragraph>
      </div>
    </AppCard>
  );
};

PlacementDetailsView.propTypes = {
  details: PropTypes.object,
};

export default PlacementDetailsView;
