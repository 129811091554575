import AppCard from '@wieldy/components/AppCard';
import {Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import PropTypes from 'prop-types';
import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';

const ItemInfo = ({label, content}) => {
  return (
    <tr>
      <td width='60%'>{label}</td>
      <td>{content}</td>
    </tr>
  );
};

ItemInfo.propTypes = {
  label: PropTypes.string,
  content: PropTypes.node,
};

const FeesPanel = () => {
  const {isSupplier} = useAccountType();
  const {companyModal} = useSelector(({user}) => user);

  const formatter = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      }),
    [],
  );

  const handleRenderCurrency = useCallback(
    (fees) => {
      if (fees === -1) return 'Infinity';

      return formatter.format(+fees);
    },
    [formatter],
  );

  if (!isSupplier || !companyModal?._orignalProps?.msp_fee?.length) {
    return null;
  }

  return (
    <AppCard className='mt-6'>
      <Typography.Title level={3} className={'mb-3'}>
        Administrative Fees
      </Typography.Title>
      <table className='table' style={{margin: '0 -8px 12px'}}>
        {companyModal._orignalProps.msp_fee.map((fee) => (
          <ItemInfo
            key={fee.end}
            label={`${handleRenderCurrency(fee.start)} - 
        ${handleRenderCurrency(fee.end)}`}
            content={`${fee.percentage}%`}
          />
        ))}
      </table>
    </AppCard>
  );
};

export default FeesPanel;
