import 'moment/locale/en-gb'; // important!

import AppLoader from '@wieldy/components/AppLoader';
import {ConfigProvider} from 'antd';
import en_GB from 'antd/lib/locale-provider/en_GB';
import {IS_AUTH_0} from 'app/config/auth';
import {useAuth00} from 'app/hooks/useAuth00';
import {useAuth1} from 'app/hooks/useAuth1';
import propTypes from 'prop-types';
import React, {useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {useAccountType} from '../hooks/useAccountType';
import {Error403} from '../pages/common/errors';
import AppTerms from '../pages/common/Terms/AppTerms';

const InitContainer = ({children}) => {
  const useAuth = IS_AUTH_0 ? useAuth00 : useAuth1;
  const {setAuth} = useAuth();

  const {isLoading, error, currentAccount, accountType} = useSelector(
    ({user}) => user,
  );
  const {isMarketplace} = useAccountType();

  const isShowTerms = useMemo(() => {
    const data = currentAccount[accountType];
    if (data) {
      if (typeof data.accepted_terms_and_conditions === 'boolean') {
        return !data.accepted_terms_and_conditions;
      }
      return false;
    }
    return false;
  }, [accountType, currentAccount]);

  useEffect(() => {
    setAuth().then();
  }, [setAuth]);

  if (error) {
    return <Error403 />;
  }

  if (isLoading) {
    return <AppLoader />;
  }
  if (isShowTerms && !isMarketplace) {
    return <AppTerms />;
  }

  return <ConfigProvider locale={en_GB}>{children}</ConfigProvider>;
};

InitContainer.propTypes = {
  children: propTypes.node,
};

export default InitContainer;
