import {Descriptions, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {
  GUARANTEE_PERIOD,
  GUARANTEE_PERIOD_ACTION,
  RECRUITMENT_FEE_STRUCTURE,
} from '../../../constants';
import {useCalcFee} from '../../../hooks/useCalcFee';

const RecruitmentTerms = ({requisition}) => {
  const {isFixed, fee} = useCalcFee(requisition);

  return (
    <div className='mb-5'>
      <Typography.Title level={4} className='mb-5'>
        Recruitment Terms
      </Typography.Title>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Fee Structure'>
          {isFixed
            ? RECRUITMENT_FEE_STRUCTURE.fixed_fee
            : `${requisition.recruitment_fee} % of salary`}
        </Descriptions.Item>
        <Descriptions.Item label='Recruitment Fee'>{fee}</Descriptions.Item>
        <Descriptions.Item label='Guarantee Period'>
          {GUARANTEE_PERIOD[requisition.guarantee_period]}
        </Descriptions.Item>
        <Descriptions.Item label='Guarantee Action'>
          {GUARANTEE_PERIOD_ACTION[requisition.guarantee_period_action]}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

RecruitmentTerms.propTypes = {
  requisition: PropTypes.object,
};

export default React.memo(RecruitmentTerms);
