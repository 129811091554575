import './SelectedList.style.less';

import AppCard from '@wieldy/components/AppCard';
import AppTable from '@wieldy/components/AppTable';
import AppInputNumber from '@wieldy/components/dataEntry/AppInputNumber';
import {getInputPlaceHolder} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Col, Form, Row, Typography} from 'antd';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React, {forwardRef, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';

import {APPLICANT_TYPE_KEYS} from '../../../../../../../constants';
import useStep from '../../../../../../../hooks/useStep';
import ApplicantModal from '../../../../../../../modals/ApplicantModal';
import ResourceModal from '../../../../../../../modals/ResourceModal';
import {formatBillingRateRage} from '../../../../../../../utils/helpers/DataFormatHelper';
import {preventNegativeNumber} from '../../../../../../../utils/helpers/preventNegativeNumber';

const SelectedList = forwardRef((props, ref) => {
  const {candidates} = useSelector(({submission}) => submission);
  const {nextStep} = useStep();

  const data = candidates.map((el) =>
    el.type ? new ApplicantModal(el) : new ResourceModal(el),
  );

  const initialValue = data.reduce((acc, value) => {
    if (value.type) {
      acc[`${value.id}.base_salary`] = value.base_salary;
      acc[`${value.id}.base_salary_min`] = value.base_salary_min;
      acc[`${value.id}.base_salary_max`] = value.base_salary_max;

      return acc;
    }

    acc[`${value.id}.billing_rate`] = value.billing_rate;

    return acc;
  }, {});

  const refInit = useRef(initialValue);

  useEffect(() => {
    if (!isEmpty(refInit.current)) {
      ref.current.setFieldsValue(refInit.current);
    }
  }, [ref]);

  const isAllApplicants = data.every(
    (item) => item.type === APPLICANT_TYPE_KEYS.permanent,
  );

  const validate = (_, value, callback) => {
    const numberRegex = /^\d*(.\d{0,2})?$/;
    if (value && !numberRegex.test(value)) {
      callback(`${isAllApplicants ? 'Salary' : 'Billing Rate'} is not a valid`);
    } else {
      callback();
    }
  };

  const columns = [
    {
      title: 'Name/Location',
      dataIndex: 'id',
      key: 'id',
      render: (_, item) => {
        return (
          <>
            <Typography.Title level={5} className={'text-sm mb-0'}>
              {item.name}
            </Typography.Title>
            <Typography.Text>{item.location}</Typography.Text>
          </>
        );
      },
    },
    {
      title: 'Available On',
      dataIndex: 'available_on',
      key: 'available_on',
    },
    {
      title: isAllApplicants
        ? 'Applicant Salary'
        : 'Resource/Applicant Billing Rate',
      dataIndex: 'billingRateLabel',
      key: 'billingRateLabel',
      render: (billingRateLabel, row) => {
        return (
          <Typography.Text>
            {row.isPermanent
              ? `${formatBillingRateRage(
                  row.base_salary,
                  row.base_salary_min,
                  row.base_salary_max,
                  false,
                )} / year`
              : billingRateLabel}
          </Typography.Text>
        );
      },
    },
    {
      title: isAllApplicants ? 'Candidate Salary' : 'Candidate Billing Rate',
      key: 'candidatesBillingRate',
      render: (_, {id}) => {
        if (isAllApplicants) {
          // if (row.base_salary) {
          //   return (
          //     <Row gutter={4} align='middle' justify='center' wrap={false}>
          //       <Col>
          //         <Form.Item
          //           name={`${id}.base_salary`}
          //           rules={[
          //             {required: true, message: ''},
          //             {validator: validate, message: ''},
          //           ]}
          //           className='mb-0'>
          //           <AppInputNumber
          //             placeholder={getInputPlaceHolder('rate')}
          //             prefix='$'
          //             type='number'
          //             pattern='[0-9]+([\.,][0-9]+)?'
          //             step='any'
          //             disabled={!props.isShowPreview}
          //             className='app-input-number-wrapper'
          //             min={0}
          //             style={{width: 100}}
          //             controls={false}
          //             stringMode={true}
          //             onKeyPress={preventNegativeNumber}
          //           />
          //         </Form.Item>
          //       </Col>
          //       <Col>
          //         <span> / year</span>
          //       </Col>
          //     </Row>
          //   );
          // }

          return (
            <Row gutter={4} align='middle' justify='center' wrap={false}>
              <Col>
                <Form.Item
                  name={`${id}.base_salary_min`}
                  rules={[
                    {required: true, message: ''},
                    {validator: validate, message: ''},
                  ]}
                  className='mb-0'>
                  <AppInputNumber
                    placeholder={getInputPlaceHolder('salary')}
                    prefix='$'
                    type='number'
                    pattern='[0-9]+([\.,][0-9]+)?'
                    step='any'
                    disabled={!props.isShowPreview}
                    className='app-input-number-wrapper'
                    min={0}
                    style={{width: 100}}
                    controls={false}
                    stringMode={true}
                    onKeyPress={preventNegativeNumber}
                  />
                </Form.Item>
              </Col>
              <Col>-</Col>
              <Col>
                <Row gutter={4} align='middle' wrap={false}>
                  <Col>
                    <Form.Item
                      name={`${id}.base_salary_max`}
                      rules={[
                        {required: true, message: ''},
                        {validator: validate, message: ''},
                      ]}
                      className='mb-0'>
                      <AppInputNumber
                        placeholder={getInputPlaceHolder('salary')}
                        prefix='$'
                        type='number'
                        pattern='[0-9]+([\.,][0-9]+)?'
                        step='any'
                        disabled={!props.isShowPreview}
                        className='app-input-number-wrapper'
                        min={0}
                        style={{width: 100}}
                        controls={false}
                        stringMode={true}
                        onKeyPress={preventNegativeNumber}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <span> / year</span>
                  </Col>
                </Row>
              </Col>
            </Row>
          );
        }
        return (
          <Form.Item
            name={`${id}.billing_rate`}
            rules={[
              {required: true, message: ''},
              {validator: validate, message: ''},
            ]}
            className='mb-0'>
            <AppInputNumber
              placeholder={getInputPlaceHolder('rate')}
              prefix='$'
              type='number'
              pattern='[0-9]+([\.,][0-9]+)?'
              step='any'
              disabled={!props.isShowPreview}
              className='app-input-number-wrapper'
              min={0}
              style={{width: 100}}
              controls={false}
              stringMode={true}
              onKeyPress={preventNegativeNumber}
            />
          </Form.Item>
        );
      },
    },
    {
      title: 'Resource/Applicant Status',
      dataIndex: 'availabilityName',
      key: 'statusName',
      render: (status) => <Typography.Text>{status}</Typography.Text>,
    },
  ];

  const handleContinue = async () => {
    try {
      await ref.current.validateFields();

      nextStep();
    } catch (e) {
      console.log();
    }
  };

  return (
    <>
      <Typography.Paragraph>
        {candidates.length} candidates selected
      </Typography.Paragraph>
      <AppCard>
        <Form ref={ref}>
          <AppTable columns={columns} dataSource={data} pagination={false} />
          {props.isShowPreview && (
            <Button type='primary mt-3' onClick={handleContinue}>
              Continue
            </Button>
          )}
        </Form>
      </AppCard>
    </>
  );
});

SelectedList.propTypes = {
  candidates: PropTypes.array,
  isShowPreview: PropTypes.bool,
};
SelectedList.displayName = 'SelectedList';

export default SelectedList;
