import {INVOICE_STATUSES_AVAILABLE} from '@wieldy/constants/AppDataConsts';
import moment from 'moment';

import {DATE_FORMATS} from '../config/app';
import {
  GUARANTEE_PERIOD_VALUES,
  RECRUITMENT_FEE_STRUCTURE_KEYS,
  WORK_TYPES,
} from '../constants';
import {calcAmountByPercent} from '../utils/helpers/CalcAmountByPercent';
import {
  formatAmount,
  formatBillingRateRage,
  formatDate,
  formatMinutes,
} from '../utils/helpers/DataFormatHelper';
import ApplicantModal from './ApplicantModal';
import BaseModal from './BaseModal';
import CandidateModal from './CandidateModal';
import ClientModal from './ClientModal';
import RequisitionModal from './RequisitionModal';
import SupplierModal from './SupplierModal';

class PlacementsModal extends BaseModal {
  constructor(placement = {}, isSupplier) {
    super();
    this.id = placement?.id;
    this.hid = placement?.hid;
    this.requisition = new RequisitionModal(placement?.requisition);
    this.applicant = new ApplicantModal(placement.applicant);
    this.candidate = new CandidateModal(placement.candidate);
    this.supplier = new SupplierModal(
      this.buildExpandSupplier(placement, true),
    );
    this.client = new ClientModal(this.buildExpandClient(placement, true));
    this.user = isSupplier ? this.client : this.supplier;
    this.name = this.user.name;
    this.created_at = this.formatDate(placement?.created_at, DATE_FORMATS.md);
    this.updated_at = this.formatDate(placement?.updated_at, DATE_FORMATS.lg);
    this.updated_relative = this.formatRelativeTime(placement?.updated_at);
    this.status = placement?.status;
    this.statusName = this.getStatus(placement?.status);
    this.isOpen = this.status !== 'completed';
    this.type = WORK_TYPES[placement?.type] || 'contract';
    //JOB INFO START ----->
    this.job_status = placement?.job_status || null;
    this.job_worksite = placement?.job_worksite || null;
    this.job_worksite_location = placement?.job_worksite_location || null;
    this.job_travel_requirements = placement?.job_travel_requirements || null;
    this.job_flsa_eligible = placement?.job_flsa_eligible || null;

    this.tax_labor = placement?.tax_labor || 0;
    this.charge_tax = !!placement?.charge_tax;
    this.tax_country = placement?.tax_country || 0;
    this.tax_state = placement?.tax_state || 0;
    this.tax_city = placement?.tax_city || 0;
    this.job_payment_term = placement?.job_payment_term || null;

    this.city = placement.city || null;
    this.job_country = placement.job_country || null;
    this.united_states = placement.united_states || null;
    //JOB INFO END <-----
    //INVOICE INFO START ------>

    // TODO: make here changes for placement for spending summary
    // TODO: GET FROM BACKEND. TEMPORARY HARDCODED

    this.ajusted_recruitment_fee = '$0.00';
    this.total_reported_hours = formatMinutes(
      placement?.invoice_total_reported_minutes,
    );
    this.invoice_ajusted_hours = formatMinutes(
      placement?.invoice_adjusted_minutes,
    );
    this.invoice_hours = formatMinutes(placement?.invoice_minutes);
    this.invoice_total_time = formatAmount(placement?.invoice_total_time);
    this.invoice_total_reported_expenses = formatAmount(
      placement?.invoice_total_reported_expenses,
    );
    this.invoice_adjusted_expenses = formatAmount(
      placement?.invoice_adjusted_expenses,
    );
    this.invoice_expenses = formatAmount(placement?.invoice_expenses);
    this.invoice_total_spend = formatAmount(placement?.invoice_total_spend);
    this.invoice_total_adjustment = formatAmount(
      placement?.invoice_total_adjustment,
    );
    this.invoice_total_net_amount = formatAmount(
      placement?.invoice_total_net_amount,
    );
    //INVOICE INFO END <------
    this.start_date = formatDate(placement?.start_date, DATE_FORMATS.md);
    this.end_date = formatDate(placement?.end_date, DATE_FORMATS.md);
    this.diff = placement?.end_date
      ? moment(placement?.end_date).diff(placement.start_date, 'days')
      : null;
    this.original_end_date = formatDate(
      placement?.original_end_date,
      DATE_FORMATS.md,
    );

    this.hiring_manager = placement?.hiring_manager;
    this.account_manager = placement?.account_manager;
    this.resource_manager = placement?.resource_manager;
    this.resource_user = placement?.resource_user || null;
    this.base_salary = placement?.base_salary || null;
    this.billing_rate = placement?.billing_rate || null;
    this.isFixedFee =
      placement?.recruitment_fee_structure ===
      RECRUITMENT_FEE_STRUCTURE_KEYS.fixed_fee;
    this.recruitment_fee = placement?.recruitment_fee || null;
    this.recruitment_fee_amount = this?.recruitment_fee
      ? this.isFixedFee
        ? formatBillingRateRage(this?.recruitment_fee, null, null)
        : formatBillingRateRage(
            calcAmountByPercent(
              placement?.base_salary || placement.billing_rate * 2080,
              placement?.recruitment_fee,
            ),
            null,
            null,
          )
      : null;
    this.invoiced_recruitment_fee = INVOICE_STATUSES_AVAILABLE.includes(
      placement?.invoice?.status,
    )
      ? this.recruitment_fee_amount
      : '$0.00';
    this.recruitment_fee_structure =
      placement?.recruitment_fee_structure || null;
    this.guarantee_period = placement?.guarantee_period || null;
    this.guarantee_period_action = placement?.guarantee_period_action || null;
    this.guarantee_end_date = GUARANTEE_PERIOD_VALUES[this.guarantee_period]
      ? formatDate(
          moment(placement?.start_date).add(
            GUARANTEE_PERIOD_VALUES[this.guarantee_period],
            'days',
          ),
          DATE_FORMATS.md,
        )
      : this.start_date;
    this.part_time = placement?.part_time;
    this.partTime = this.part_time ? 'Yes' : 'No';

    this.jobLocation = this.getLocation(placement);
  }
}

export default PlacementsModal;
