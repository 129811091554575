import {JOB_FUNCTIONS_MAPPED} from '@register-app/constants/job-functions';
import {Descriptions} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';

import {ACCEPT_CANDIDATES_OPTIONS} from '../../../constants/work-types';
import {useAccountType} from '../../../hooks/useAccountType';
import {INSURANCE} from '../../../pages/main/requisitions/ViewRequisition/constatns';
import UserPopover from '../../../shared/components/UserPopover';
import MultipleValuePreview from '../../components/MultipleValuePreview';

const JobDescription = ({requisition}) => {
  const {isClient, isSupplier} = useAccountType();
  const {currentAccount} = useSelector(({user}) => user);
  console.log(requisition, '-----ddd');

  return (
    <div className={'mb-5'}>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Filled/Openings'>
          {`${requisition.filled}/${requisition.openings}`}
        </Descriptions.Item>
        <Descriptions.Item label='Part Time'>
          {requisition.partTime}
        </Descriptions.Item>
        <Descriptions.Item label='Job Function'>
          {JOB_FUNCTIONS_MAPPED[requisition.job_function]}
        </Descriptions.Item>
        <Descriptions.Item label='Type'>
          {requisition.workType}
        </Descriptions.Item>
        {requisition.contractType && (
          <Descriptions.Item label='Contract Type'>
            {requisition.contractType}
          </Descriptions.Item>
        )}
        <Descriptions.Item label='Priority'>
          {requisition?.jobPriority}
        </Descriptions.Item>
        <Descriptions.Item label='Accept Candidates From'>
          <MultipleValuePreview
            type={requisition.accept_candidates_from}
            source={ACCEPT_CANDIDATES_OPTIONS}
          />
        </Descriptions.Item>
        {!isSupplier && (
          <Descriptions.Item label='Recruiter'>
            <UserPopover user={requisition?.recruiter} />
          </Descriptions.Item>
        )}
        {requisition?.hiringManager?.id && (
          <Descriptions.Item label='Hiring Manager'>
            <UserPopover user={requisition?.hiringManager} />
          </Descriptions.Item>
        )}
        {isClient ? (
          <Descriptions.Item label='Insurance Required'>
            {currentAccount.client?.insurance_required
              ? INSURANCE.REQUIRED
              : INSURANCE.DEFAULT}
          </Descriptions.Item>
        ) : (
          <Descriptions.Item label='Insurance Required'>
            {requisition.insuranceRequired
              ? INSURANCE.REQUIRED
              : INSURANCE.DEFAULT}
          </Descriptions.Item>
        )}
        <Descriptions.Item label='Requisition ID' span={1}>
          {requisition.hid}
        </Descriptions.Item>
        {requisition?.createdAt && (
          <Descriptions.Item label='Created'>
            {requisition?.createdAt}
          </Descriptions.Item>
        )}
        {requisition?.updatedAt && (
          <Descriptions.Item label='Updated' span={1}>
            {requisition?.updatedAt}
          </Descriptions.Item>
        )}
      </Descriptions>
    </div>
  );
};

JobDescription.propTypes = {
  requisition: PropTypes.object,
};

export default React.memo(JobDescription);
