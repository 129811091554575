import AppCard from '@wieldy/components/AppCard';
import AppLoader from '@wieldy/components/AppLoader';
import AppHeaderPagination from '@wieldy/components/AppPagination/AppHeaderPagination';
import AppTable from '@wieldy/components/AppTable';
import AppTag from '@wieldy/components/AppTag';
// import FilterControls from '@wieldy/components/TimePeriodFilterControl';
import TimePeriodFilterControl from '@wieldy/components/TimePeriodFilterControl';
import {getTablePagination} from '@wieldy/utils/helpers/TableHelper';
import {Empty, Modal, Space} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useBrowse} from 'app/hooks/useBrowse';
import {useInvoiceHeaders} from 'app/hooks/useInvoiceHeaders';
import {useTableDateFilter} from 'app/hooks/useTableDateFilter';
import {INVOICE_ACTIONS} from 'app/pages/main/financials/MainContent/data';
import SubmitModal from 'app/pages/main/financials/ViewInvoice/SubmitModal';
import {
  useDeleteInvoice,
  useGetInvoices,
  useUpdateInvoice,
} from 'app/services/invoiceService';
import {
  resetInvoiceActions,
  setInvoiceStatus,
} from 'app/store/features/invoice/invoiceSlice.js';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {useTableSort} from '../../../../hooks/useTableSort';
import ChangeInvoiceStatus from './ChangeInvoiceStatus';
import {getColumns} from './columns';

const MainContent = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const {isSupplier, isMarketplace} = useAccountType();
  const [modal, component] = Modal.useModal();
  const {pageSize, currentPage, onChangePage, onShowSizeChange} = useBrowse({
    initPageSize: 10,
  });

  const [filter, onChangeFilter] = useTableDateFilter();
  const userParams = useInvoiceHeaders();

  const {sort, handleSort} = useTableSort({
    ['created_at']: 'descend',
  });
  const onChangeTable = useCallback(
    (a, b, sorter) => {
      if (sorter.columnKey && sorter?.order) {
        handleSort(sorter.columnKey, sorter?.order);
      }
    },
    [handleSort],
  );

  const {data, isLoading} = useGetInvoices({
    limit: pageSize,
    page: currentPage - 1,
    sort,
    'created_at[gte]': filter?.gte,
    'created_at[lte]': filter?.lte,
    ...userParams,
  });

  const [invoices, invoicesTotal] = getItemsData('items', data);

  const {invoiceActions} = useSelector(({invoice}) => invoice);
  const {type, title, id: itemId, status} = invoiceActions;

  const showFilter = !!invoicesTotal || !!filter.gte || !!filter.lte;

  const {mutate} = useUpdateInvoice();
  const {mutate: deleteMutate} = useDeleteInvoice();

  const handleCancel = useCallback(() => {
    dispatch(resetInvoiceActions());
  }, [dispatch]);

  const handleOnFinish = useCallback(
    (formData) => {
      if (!itemId) {
        return;
      }

      const payload = {...formData, id: itemId};
      mutate(payload, {
        onSuccess: () => {
          dispatch(resetInvoiceActions());
        },
      });
    },
    [dispatch, itemId, mutate],
  );

  const [resend, setResend] = useState(null);

  const handleCancelResend = useCallback(() => {
    setResend(null);
  }, []);

  const bulkActions = useCallback(
    ({id, status, client_billing_address, hiring_manager}) => {
      if (!isMarketplace || isSupplier) return null;

      const common = [];

      const remove = {
        title: 'Delete invoice',
        onClick: () => {
          modal.confirm({
            title: `Delete this invoice?`,
            onOk: async () => deleteMutate(id),
            okText: 'Delete',
          });
        },
      };
      const resend = {
        title: 'Resend invoice',
        onClick: () => {
          setResend({id, client_billing_address, hiring_manager});
        },
      };

      const changeStatus = {
        title: 'Change status',
        onClick: () => {
          dispatch(setInvoiceStatus({id, status}));
        },
      };

      common.push(changeStatus);

      if (status === 'prepared' || status === 'disputed') {
        common.push(remove);
      }
      if (status === 'submitted_to_client' && isMarketplace) {
        common.push(resend);
      }
      return common.length ? common : null;
    },
    [deleteMutate, dispatch, isMarketplace, isSupplier, modal],
  );

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <React.Fragment>
      <Modal
        title={title}
        visible={!!type || false}
        width={650}
        destroyOnClose={true}
        footer={null}
        onCancel={handleCancel}>
        {type === INVOICE_ACTIONS.CHANGE_STATUS && (
          <ChangeInvoiceStatus
            onCancel={handleCancel}
            onFinish={handleOnFinish}
            status={status}
          />
        )}
      </Modal>
      <AppCard
        title={
          <React.Fragment>
            Invoices{' '}
            {!!invoicesTotal && (
              <AppTag className='ml-1'>{invoicesTotal}</AppTag>
            )}
          </React.Fragment>
        }
        headStyle={{borderBottom: 0}}
        bodyStyle={{paddingTop: 0}}
        extra={
          showFilter && (
            <Space>
              <TimePeriodFilterControl onChange={onChangeFilter} />
              <AppHeaderPagination
                total={invoicesTotal}
                current={currentPage}
                pageSize={pageSize}
                onChange={onChangePage}
                hideStartBorder
              />
            </Space>
          )
        }>
        <AppTable
          className={'column-valign-top'}
          columns={getColumns({isSupplier, navigation})}
          onChange={onChangeTable}
          dataSource={invoices}
          showSorterTooltip={false}
          bulkActions={bulkActions}
          emptyText={<Empty description='No Available Invoices' />}
          pagination={getTablePagination(
            pageSize,
            currentPage,
            invoicesTotal,
            onShowSizeChange,
            onChangePage,
          )}
        />
      </AppCard>
      {component}
      {!!resend && (
        <SubmitModal
          invoice={resend}
          isOpen={!!resend}
          handleClose={handleCancelResend}
          isResend={true}
        />
      )}
    </React.Fragment>
  );
};

MainContent.propTypes = {
  setTotalInvoices: PropTypes.func,
};

export default MainContent;
