import './AppRevealCard.style.less';

import PropTypes from 'prop-types';
import React, {useLayoutEffect, useState} from 'react';
import {RiCloseFill} from 'react-icons/ri';

//import {useMeasure} from '../../hooks/useMeasure';
import AppDrawer from '../AppDrawer';
import AppScrollbar from '../AppScrollbar';

const AppRevealCard = ({
  title,
  revealed,
  onClose,
  revealComponent,
  children,
}) => {
  const [revealRef, setRevealRef] = useState();
  const [boundary, setBoundary] = useState({});

  useLayoutEffect(() => {
    if (revealRef) {
      setBoundary(revealRef?.getBoundingClientRect());
    }
  }, [revealRef]);

  return (
    <div className='app-reveal-card' style={{height: '100%'}}>
      {children}
      <AppDrawer
        height={boundary?.height ? boundary?.height + 32 : null}
        title={title}
        closeIcon={<RiCloseFill className={'text-xxl'} />}
        placement='bottom'
        onClose={onClose}
        visible={revealed}
        getContainer={false}
        style={{position: 'absolute'}}
        bodyStyle={{padding: 0}}>
        <AppScrollbar>
          <div ref={setRevealRef}>{revealComponent}</div>
        </AppScrollbar>
      </AppDrawer>
    </div>
  );
};

AppRevealCard.propTypes = {
  title: PropTypes.string,
  revealed: PropTypes.bool,
  setReveal: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node,
  revealComponent: PropTypes.node,
};

export default AppRevealCard;
