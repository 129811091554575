import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppDatePicker from '@wieldy/components/dataEntry/AppDatePicker';
import AppInput from '@wieldy/components/dataEntry/AppInput';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getInputPlaceHolder,
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, DatePicker, Form} from 'antd';
import RequisitionHelper from 'app/utils/RequisitionHelper';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

import {isValidDateRange} from '../../../../utils/helpers/IsValidDateRange';
import LocationHelper from '../../../../utils/LocationHelper';

const RangePicker = DatePicker.RangePicker;

const InformationForm = ({initialValues, onSave, onContinue}) => {
  const [country, setCountry] = useState(null);

  useEffect(() => {
    if (initialValues?.job_country) {
      setCountry(initialValues?.job_country);
    }
  }, [initialValues?.job_country]);
  const onFormSubmit = (formData) => {
    const {start_end_date, start_date, ...rest} = formData;
    if (start_end_date) {
      onSave({
        ...rest,
        start_date: start_end_date[0]?.unix(),
        end_date: start_end_date[1]?.unix(),
      });
    } else {
      onSave({
        ...rest,
        start_date: start_date?.unix(),
      });
    }

    onContinue();
  };

  return (
    <AppCard>
      <AppFormWrapper>
        <Form
          layout='vertical'
          onFinish={onFormSubmit}
          autoComplete='off'
          initialValues={initialValues}>
          <AppFormBody>
            <Form.Item
              name='job_worksite'
              label='Work Model'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Work Model'),
                },
              ]}>
              <AppSelect
                style={{maxWidth: 280}}
                placeholder={getSelectPlaceHolder('work model')}
                options={RequisitionHelper.worksites()}
              />
            </Form.Item>
            <div style={{display: 'flex', gap: 10}}>
              <Form.Item
                name='job_country'
                label='Country'
                style={{maxWidth: 280, width: '100%'}}
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Job Location'),
                  },
                ]}>
                <AppSelect
                  onChange={(_, val) => setCountry(val?.value)}
                  showSearch
                  placeholder={getSelectPlaceHolder('job location')}
                  options={RequisitionHelper.countries()}
                />
              </Form.Item>
              {country === 'united_states' && (
                <Form.Item
                  style={{maxWidth: 280, width: '100%'}}
                  name='us_state'
                  label='State'
                  rules={[
                    {
                      required: true,
                      message: getRequiredMessage('State'),
                    },
                  ]}>
                  <AppSelect
                    showSearch
                    placeholder={getSelectPlaceHolder('state')}
                    options={LocationHelper.getUsStates()}
                  />
                </Form.Item>
              )}
              <Form.Item
                style={{maxWidth: 280, width: '100%'}}
                name='city'
                label='City'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('City'),
                  },
                ]}>
                <AppInput placeholder={getInputPlaceHolder('city')} />
              </Form.Item>
            </div>

            {'job_duration' in initialValues && (
              <Form.Item
                name='job_duration'
                label='Duration'
                rules={[
                  {
                    required: false,
                    message: getRequiredMessage('Duration'),
                  },
                ]}>
                <AppSelect
                  showSearch
                  style={{maxWidth: 280}}
                  placeholder={getSelectPlaceHolder('duration')}
                  options={RequisitionHelper.durations()}
                />
              </Form.Item>
            )}

            {initialValues.start_end_date ? (
              <Form.Item
                label={'Start Date - End Date'}
                name='start_end_date'
                rules={[
                  {
                    required: true,
                    validator: isValidDateRange(
                      getRequiredMessage('Start & End date'),
                    ),
                  },
                ]}>
                <RangePicker
                  style={{width: '100%', maxWidth: 280}}
                  format='MM-DD-YYYY'
                  disabledDate={(current) =>
                    current && current < moment().startOf('day')
                  }
                />
              </Form.Item>
            ) : (
              <Form.Item
                label='Job Start Date'
                name='start_date'
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <AppDatePicker
                  placeholder={getSelectPlaceHolder('job start date')}
                  style={{width: '100%', maxWidth: 280}}
                  disabledDate={(currentDate) => {
                    return moment().isAfter(moment(currentDate).add(1, 'days'));
                  }}
                />
              </Form.Item>
            )}

            <Form.Item
              name='travel_requirements'
              label='Travel Required'
              rules={[
                {
                  required: false,
                  message: getRequiredMessage('Travel Required'),
                },
              ]}>
              <AppSelect
                style={{maxWidth: 280}}
                placeholder={getSelectPlaceHolder('travel required')}
                options={RequisitionHelper.travelRequirements()}
              />
            </Form.Item>
          </AppFormBody>

          <AppFormActions>
            <Button type='primary' htmlType='submit'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

InformationForm.propTypes = {
  initialValues: PropTypes.object,
  onSave: PropTypes.func,
  onContinue: PropTypes.func,
};

export default InformationForm;
