import AppBorderBox from '@wieldy/components/AppBorderBox';
import AppCard from '@wieldy/components/AppCard';
import AppLoader from '@wieldy/components/AppLoader';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {getRequiredMessage} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Select, Typography} from 'antd';
import useStep from 'app/hooks/useStep';
import {useGetCandidates} from 'app/services/candidateService';
import {
  setPlacementCandidate,
  setPlacementDetails,
} from 'app/store/features/placement/placementSlice';
import {getFullName} from 'app/utils/helpers/DataFormatHelper';
import {getItemsData} from 'app/utils/helpers/GetItemsData';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

const SelectCandidate = () => {
  const dispatch = useDispatch();
  const {currentStep, nextStep} = useStep();
  const {candidate, requisition, details} = useSelector(
    ({placement}) => placement,
  );
  const {data, isLoading} = useGetCandidates(
    {
      requisition: requisition.id,
      include: ['total_count', 'skill_tags'],
      expand: ['resource', 'applicant', 'resource_manager'],
      available: true,
    },
    {enabled: currentStep === 2},
  );
  const [candidates] = getItemsData('candidates', data);

  const filteredCandidates = candidates.filter(
    (el) => !el.created_from_job_board,
  );

  const onFormSubmit = (formData) => {
    const item = candidates?.find((item) => item.id === formData?.candidate);

    if (item) {
      dispatch(
        setPlacementDetails({
          ...details,
          placement_supplier_id: item.supplier.id,
          resource_manager: item.resource?.resource_manager,
        }),
      );

      dispatch(
        setPlacementCandidate({
          id: item.id,
          name: `${getFullName(item.resource || item.applicant)}`,
          inquiry:
            typeof item.inquiry === 'string'
              ? item.inquiry
              : typeof item.inquiry === 'object'
              ? item.inquiry?.id || undefined
              : undefined,
          submission:
            typeof item.submission === 'string'
              ? item.submission
              : typeof item.submission === 'object'
              ? item.submission?.id || undefined
              : undefined,
          account_manager: item.account_manager,
        }),
      );
      if (nextStep) nextStep();
    }
  };

  if (isLoading) {
    return <AppLoader />;
  }

  if (currentStep > 2) {
    return (
      <AppBorderBox
        className='d-inline-flex px-6 mt-3'
        style={{backgroundColor: '#FFF'}}>
        <Typography.Title level={4} className={'mb-0'}>
          {candidate.name}
        </Typography.Title>
      </AppBorderBox>
    );
  }
  if (currentStep === 2) {
    return (
      <AppCard
        title='Select Candidate'
        headStyle={{border: 0}}
        bodyStyle={{paddingTop: 0}}>
        <Form
          layout='vertical'
          initialValues={{candidate: candidate.id}}
          onFinish={onFormSubmit}
          autoComplete='off'>
          <Form.Item
            name='candidate'
            label='Candidate'
            rules={[
              {
                // required: true,
                message: getRequiredMessage('Candidate'),
              },
            ]}>
            <AppSelect
              showSearch
              loading={isLoading}
              placeholder='Select candidate'
              style={{minWidth: '280px', width: 'auto', maxWidth: '100%'}}>
              {filteredCandidates.map((cand) => (
                <Select.Option key={cand.id} value={cand.id}>
                  {`${getFullName(cand.resource || cand.applicant)} :: ${
                    cand.hid
                  }`}
                </Select.Option>
              ))}
            </AppSelect>
          </Form.Item>

          <Button type='primary' htmlType='submit'>
            Continue
          </Button>
        </Form>
      </AppCard>
    );
  }
  return (
    <Typography.Text type='secondary'>
      Select candidate to be deployed on this placement
    </Typography.Text>
  );
};

export default SelectCandidate;
