import {Checkbox} from 'antd';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';

const ParentChildCheckbox = ({item, name}) => {
  const {control, setValue, trigger, watch} = useFormContext();

  const parentName = `${name}.${item.value}`;
  const [childrenValues, setChildrenValues] = useState({});
  const isAnyChildChecked = Object.values(childrenValues).some(Boolean);

  const allData = watch(name);

  useEffect(() => {
    if (allData) {
      const {
        permanent_contingency_search,
        permanent_executive_search,
        permanent_retained_search,
        temp_corp_to_corp,
        temp_w_2_employment,
      } = allData;
      if (item.value === 'permanent_hires') {
        setChildrenValues({
          permanent_contingency_search,
          permanent_executive_search,
          permanent_retained_search,
        });
      } else if (item.value === 'temporary_hires') {
        setChildrenValues({
          temp_corp_to_corp,
          temp_w_2_employment,
        });
      }
    }
  }, [allData, item.value, name, parentName]);

  useEffect(() => {
    if (isAnyChildChecked) {
      setValue(parentName, true);
    }
  }, [setValue, isAnyChildChecked, parentName]);

  const renderParent = () => (
    <Controller
      name={parentName}
      control={control}
      render={({field}) => {
        const {value, onChange, ...rest} = field;
        return (
          <Checkbox
            {...rest}
            checked={value}
            onChange={async (e) => {
              onChange(e.target.checked);
              await trigger(name);
            }}
            disabled={isAnyChildChecked}>
            {item.label}
          </Checkbox>
        );
      }}
    />
  );

  if (!item.subItems) {
    return renderParent();
  }

  return (
    <>
      {renderParent()}
      <div className='form-children-checkboxes'>
        {item.subItems.map((el) => (
          <Controller
            key={el.value}
            name={`${name}.${el.value}`}
            control={control}
            render={({field}) => {
              const {value, onChange, ...rest} = field;
              return (
                <Checkbox
                  checked={value}
                  onChange={(e) => {
                    setChildrenValues((prev) => ({
                      ...prev,
                      [el.value]: e.target.checked,
                    }));
                    onChange(e.target.checked);
                  }}
                  {...rest}>
                  {el.label}
                </Checkbox>
              );
            }}
          />
        ))}
      </div>
    </>
  );
};

ParentChildCheckbox.propTypes = {
  name: PropTypes.string,
  item: PropTypes.objectOf({
    value: PropTypes.string,
    subItems: PropTypes.array,
  }),
};

export default ParentChildCheckbox;
