import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppInputNumber from '@wieldy/components/dataEntry/AppInputNumber';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Col, Form, Radio, Row, Typography} from 'antd';
import RequisitionHelper from 'app/utils/RequisitionHelper';
import PropTypes from 'prop-types';
import React, {useCallback, useMemo} from 'react';

import {BASE_SALARY_PERIOD} from '../../../../constants';
import {getOptionsFromObject} from '../../../../utils/helpers/GetOptionsFromObject';
import {allowPositiveExactNumber} from '../../../../utils/helpers/preventNegativeNumber';

const radioOptions = (isPermanent, isTemporary) => {
  const name = isTemporary ? 'Base' : 'Billing';
  return [
    {
      label: isPermanent ? 'Base salary' : `${name} Rate`,
      value: 'exact',
    },
    {
      label: isPermanent ? 'Base Salary Range' : `${name} Rate Range`,
      value: 'range',
    },
  ];
};

const InformationForm = ({
  initialValues: {
    isDirectHire,
    isTemporary,
    isContract,
    isC2C,
    ...initialValues
  },
  onSave,
  onContinue,
}) => {
  const [form] = Form.useForm();

  const isBillingRage = isTemporary || (isContract && !isC2C);

  const initialType = useMemo(() => {
    if (isBillingRage) {
      if (initialValues.billing_rate && !initialValues.billing_rate_min) {
        return 'exact';
      } else if (initialValues.billing_rate_min) {
        return 'range';
      }
    } else {
      if (initialValues.base_salary && !initialValues.base_salary_min) {
        return 'exact';
      } else if (initialValues.base_salary_min) {
        return 'range';
      }
    }

    return 'exact';
  }, [
    initialValues.base_salary,
    initialValues.base_salary_min,
    initialValues.billing_rate,
    initialValues.billing_rate_min,
    isBillingRage,
  ]);

  const [billingType, setBillingType] = React.useState(initialType);
  const onFormSubmit = (formData) => {
    const baseSalaryFields = formData.base_salary
      ? {
          base_salary: formData.base_salary,
          base_salary_min: null,
          base_salary_max: null,
        }
      : {
          base_salary: null,
          base_salary_min: formData.base_salary_min,
          base_salary_max: formData.base_salary_max,
        };

    const billingFields = formData.billing_rate
      ? {
          billing_rate: formData.billing_rate,
          billing_rate_min: null,
          billing_rate_max: null,
        }
      : {
          billing_rate: null,
          billing_rate_min: formData.billing_rate_min,
          billing_rate_max: formData.billing_rate_max,
        };

    onSave({
      ...formData,
      billing_rate_max: formData?.billing_rate_max ?? undefined,
      ...baseSalaryFields,
      ...billingFields,
      candidate_min_education: formData?.candidate_min_education ?? undefined,
      flsa_eligible: formData?.flsa_eligible ?? null,
    });
    onContinue();
  };

  const handleChangeBillingType = useCallback(
    (e) => {
      setBillingType(e.target.value);
      if (e.target.value === 'range') {
        form.setFieldValue(
          !isBillingRage ? 'base_salary' : 'billing_rate',
          undefined,
        );
      } else {
        form.setFieldValue(
          !isBillingRage ? 'base_salary_min' : 'billing_rate_min',
          undefined,
        );
        form.setFieldValue(
          !isBillingRage ? 'base_salary_max' : 'billing_rate_max',
          undefined,
        );
      }
    },
    [form, isBillingRage],
  );

  const billingValidator = (_, value, callback) => {
    if (value && !/^\d+(\.\d{1,2})?$/.test(value)) {
      return callback('Billing rate is not a valid number');
    }
    if (value && value > 1000) {
      return callback('Max amount is $1000');
    }
    return callback();
  };

  const billingValue = Form.useWatch('billing_rate_max', form);

  const isRange = billingType === 'range';

  return (
    <AppCard>
      <AppFormWrapper>
        <Form
          form={form}
          layout='vertical'
          onFinish={onFormSubmit}
          autoComplete='off'
          initialValues={{...initialValues, billing_type: billingType}}>
          <AppFormBody>
            {isDirectHire || !isC2C ? (
              <>
                <Form.Item
                  label={isBillingRage ? 'Rate' : 'Salary'}
                  name='billing_type'
                  rules={[
                    {
                      required: true,
                    },
                  ]}>
                  <Radio.Group
                    options={radioOptions(!isBillingRage, isTemporary)}
                    onChange={handleChangeBillingType}
                    value={billingType}
                  />
                </Form.Item>
                <Row align='bottom'>
                  {!isRange ? (
                    <Col>
                      <Form.Item
                        name={isBillingRage ? 'billing_rate' : 'base_salary'}
                        rules={[
                          {
                            required: !isRange,
                            message: getRequiredMessage(
                              isBillingRage ? 'Rate' : 'Salary',
                            ),
                          },
                        ]}>
                        <AppInputNumber
                          onKeyDown={allowPositiveExactNumber}
                          placeholder={`Enter exact ${
                            !isBillingRage ? 'salary' : 'rate'
                          }`}
                          prefix='$'
                          addonAfter='USD'
                          type='number'
                          pattern='[0-9]+([\.,][0-9]+)?'
                          step='any'
                          style={{width: '100%'}}
                          controls={false}
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    <>
                      <Col className='mr-2'>
                        <Form.Item
                          name={
                            !isBillingRage
                              ? 'base_salary_min'
                              : 'billing_rate_min'
                          }
                          rules={[
                            {
                              required: isRange,
                              message: getRequiredMessage(
                                isBillingRage ? 'Rate' : 'Salary',
                              ),
                            },
                          ]}>
                          <AppInputNumber
                            onKeyDown={allowPositiveExactNumber}
                            placeholder={
                              !isBillingRage ? 'From salary' : 'From rate'
                            }
                            prefix='$'
                            addonAfter='USD'
                            type='number'
                            pattern='[0-9]+([\.,][0-9]+)?'
                            step='any'
                            style={{width: '200px'}}
                            controls={false}
                          />
                        </Form.Item>
                      </Col>
                      <Col className='mr-2'>
                        <Form.Item
                          name={
                            !isBillingRage
                              ? 'base_salary_max'
                              : 'billing_rate_max'
                          }
                          rules={[
                            {
                              required: true,
                              message: getRequiredMessage(
                                isBillingRage ? 'Rate' : 'Salary',
                              ),
                            },
                          ]}>
                          <AppInputNumber
                            onKeyDown={allowPositiveExactNumber}
                            placeholder={
                              !isBillingRage ? 'To salary' : 'To rate'
                            }
                            prefix='$'
                            addonAfter='USD'
                            type='number'
                            pattern='[0-9]+([\.,][0-9]+)?'
                            step='any'
                            style={{width: '200px'}}
                            controls={false}
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
              </>
            ) : (
              <Form.Item
                style={{width: 280}}
                name='billing_rate_max'
                label='Maximum Billing Rate'
                rules={[{validator: billingValidator}]}>
                <AppInputNumber
                  placeholder='Enter amount'
                  prefix='$'
                  addonAfter='USD'
                  type='number'
                  pattern='[0-9]+([\.,][0-9]+)?'
                  step='any'
                  style={{width: '200px'}}
                  controls={false}
                />
              </Form.Item>
            )}

            {isDirectHire ? (
              <Form.Item
                style={{width: 280}}
                name='base_salary_period'
                label='Salary Period'
                rules={[
                  {
                    required: true,
                    message: getRequiredMessage('Salary Period'),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder('Salary Period')}
                  options={getOptionsFromObject(BASE_SALARY_PERIOD)}
                  allowClear={true}
                />
              </Form.Item>
            ) : (
              <Form.Item
                style={{width: 280}}
                name='billing_rate_period'
                label={`${isTemporary ? 'Base' : 'Billing'} Rate Period`}
                rules={[
                  {
                    required: !!billingValue,
                    message: getRequiredMessage(
                      `${isTemporary ? 'Base' : 'Billing'} Rate Period`,
                    ),
                  },
                ]}>
                <AppSelect
                  placeholder={getSelectPlaceHolder(
                    `${isTemporary ? 'base' : 'billing'} rate period`,
                  )}
                  options={RequisitionHelper.billingRatePeriods()}
                  allowClear={true}
                />
              </Form.Item>
            )}

            {isContract && (
              <Form.Item
                name='expenses_eligible'
                label='Expenses Eligible'
                style={{width: 280}}>
                <AppSelect
                  placeholder='Is expenses eligible'
                  options={RequisitionHelper.yesNoOptions()}
                />
              </Form.Item>
            )}

            <Form.Item
              style={{width: 280}}
              name='flsa_eligible'
              label='FLSA Eligible'>
              <AppSelect
                placeholder='Is FLSA eligible'
                options={RequisitionHelper.yesNoOptions({
                  yes: 'Exempt',
                  no: 'Non Exempt',
                })}
                allowClear={true}
              />
            </Form.Item>

            <Typography.Title level={4} className={'mb-3 mt-10'}>
              Candidate Criteria
            </Typography.Title>

            <Form.Item name='candidate_country' label='Location(s)'>
              <AppSelect
                showSearch
                placeholder={getSelectPlaceHolder(
                  'acceptable options for candidate’s location(s)',
                )}
                mode='multiple'
                options={RequisitionHelper.countries()}
              />
            </Form.Item>

            <Form.Item
              // style={}
              name='candidate_visa_status'
              label='Visa Status'>
              <AppSelect
                showSearch
                placeholder={getSelectPlaceHolder(
                  'required candidate’s visa status',
                )}
                options={RequisitionHelper.visaStatus()}
                mode='multiple'
              />
            </Form.Item>

            <Form.Item
              name='candidate_min_experience'
              label='Minimum Experience'>
              <AppSelect
                placeholder={getSelectPlaceHolder('required # of years')}
                options={RequisitionHelper.candidateMinExperience()}
                allowClear={true}
              />
            </Form.Item>

            <Form.Item name='candidate_min_education' label='Minimum Education'>
              <AppSelect
                placeholder={getSelectPlaceHolder(
                  'required level of education',
                )}
                options={RequisitionHelper.educations()}
                allowClear={true}
              />
            </Form.Item>
            <Form.Item name='supplier_country' label='Supplier Based In'>
              <AppSelect
                showSearch
                placeholder={getSelectPlaceHolder(
                  'acceptable options for supplier’s primary location',
                )}
                mode='tags'
                options={RequisitionHelper.countries()}
              />
            </Form.Item>

            <Typography.Title level={5} className={'mb-3 mt-10'}>
              Required Background Checks
            </Typography.Title>

            <Form.Item name='candidate_required_checks' label='Required Checks'>
              <AppSelect
                mode='multiple'
                placeholder={getSelectPlaceHolder('required background checks')}
                options={RequisitionHelper.backgroundChecks()}
              />
            </Form.Item>
          </AppFormBody>

          <AppFormActions>
            <Button type='primary' htmlType='submit'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

InformationForm.propTypes = {
  initialValues: PropTypes.object,
  onSave: PropTypes.func,
  onContinue: PropTypes.func,
};

export default InformationForm;
