import AppLoader from '@wieldy/components/AppLoader';
import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppTag from '@wieldy/components/AppTag/AppTag';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {INQUIRY_STATUS} from '@wieldy/constants/AppDataConsts';
import {Button, Divider, Space, Tooltip} from 'antd';
import {messagesAPI} from 'app/api/messages';
import MESSAGE_QUERIES from 'app/constants/query-params/message-queries';
import {useAccountType} from 'app/hooks/useAccountType';
import {useNotes} from 'app/hooks/useNotes';
import RequisitionModal from 'app/modals/RequisitionModal';
import {useGetInquiries} from 'app/services/inquiryService';
import {
  useCreateInquiryChannel,
  useCreateSubmissionChannel,
} from 'app/services/messages';
import {
  useAddBookmarkToRequisition,
  useGetRequisition,
} from 'app/services/requisitionService';
import {useGetSubmissions} from 'app/services/submissionService';
import {getUserName} from 'app/utils/helpers/ConvertHID';
import NotesBtnIcon from 'assets/icons/NotesBtn';
import clsx from 'clsx';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {RiMessageLine, RiStarLine, RiStarSFill} from 'react-icons/ri';
import {useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import {supplierTypes} from '../../../../constants/supplier-types';
import {CONSTRACT_OPTIONS_KEYS} from '../../../../constants/work-types';
import {
  useAcceptDeclineSupplierRequisitionInvitation,
  useGetSupplierRequisitionInvitation,
} from '../../../../services/requisitionInvitationService';
import {getPrimaryTabs, getQueryParams, TABS} from './constatns';
import MainContent from './MainContent';
import ManageSupplierModal from './ManageSupplierModal';

const ViewRequisition = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const [channel, setChannel] = useState(null);
  const supplierType = useSelector(
    ({user}) => user.currentAccount.supplier?.type,
  );

  const closedStatuses = ['closed', 'engaged', 'withdrawn', 'declined'];

  const {isClient, isSupplier, isMarketplace} = useAccountType();

  const [isOpenManageSuppliersModal, setIsOpenManageSuppliersModal] =
    useState(false);

  const handleCloseModal = () => {
    setIsOpenManageSuppliersModal(false);
  };

  const queryParams = useMemo(
    () =>
      isClient
        ? {
            expand: ['hiring_manager', 'recruiter'],
            include: ['skill_tags'],
          }
        : {include: ['bookmarked', 'skill_tags'], expand: ['client']},
    [isClient],
  );

  const {isLoading, data} = useGetRequisition(id, queryParams);

  const requisition = useMemo(() => new RequisitionModal(data), [data]);

  const getRequisitionInvitation = useGetSupplierRequisitionInvitation(
    requisition.id,
    {enabled: !!requisition && isSupplier},
  );

  const createInquiryChannel = useCreateInquiryChannel();
  const createSubmissionChannel = useCreateSubmissionChannel();
  const acceptDeclineRequisitionInvitation =
    useAcceptDeclineSupplierRequisitionInvitation();

  const isOpen = requisition?.status === 'open';

  const isOnlyAcceptStaffingAgencies = useMemo(() => {
    return requisition.accept_candidates_from?.every(
      (item) => item === CONSTRACT_OPTIONS_KEYS.staffing_agencies,
    );
  }, [requisition]);

  const isOnlyTalentVendor = useMemo(() => {
    return requisition.accept_candidates_from?.every(
      (item) => item === CONSTRACT_OPTIONS_KEYS.talent_vendors,
    );
  }, [requisition]);

  const isShowManageSupplierButton = useMemo(() => {
    if (requisition.isOnlyDirectSourcing) return false;

    if (isMarketplace && isClient) {
      return !isOnlyTalentVendor;
    }

    return false;
  }, [isMarketplace, isClient, isOnlyTalentVendor, requisition]);

  const [tabView, setTabView] = useState({
    isInquiries: false,
    isSubmissions: false,
    isCandidates: false,
  });

  const {isInquiries, isSubmissions, isCandidates} = tabView;

  const handleSetTabView = useCallback((type, value) => {
    setTabView((prev) => ({...prev, [type]: value}));
  }, []);

  const mutationBookmark = useAddBookmarkToRequisition(data?.id, true);

  const {isLoading: isSubmissionLoading, data: submissionData} =
    useGetSubmissions(
      {
        requisition: id,
        ...getQueryParams(TABS.SUBMISSIONS, id),
      },
      {
        enabled: tabView.isCandidates,
      },
    );

  const {data: inquiriesData} = useGetInquiries({
    requisition: id,
    ...getQueryParams(TABS.INQUIRIES, id),
  });

  useEffect(() => {
    const fetchChannel = async () => {
      if (isMarketplace || isClient) return null;
      if (isSupplier) {
        const inquiry = inquiriesData?.inquiries[0];
        if (inquiry) {
          setChannel(true);
          return;
        }
        const submission = submissionData?.submissions.find(
          (submission) =>
            submission.status === INQUIRY_STATUS.in_review ||
            submission.status === INQUIRY_STATUS.responded,
        );
        if (submission) {
          const channelId = `${submission.requisition}_${submission.client.id}_${submission.supplier.id}`;
          try {
            const result = await messagesAPI.isChannel(channelId);
            setChannel(result);
            // eslint-disable-next-line no-empty
          } catch (_err) {}
        }
        return null;
      }
    };

    fetchChannel();
  }, [
    inquiriesData?.inquiries,
    isClient,
    isMarketplace,
    isSupplier,
    submissionData?.submissions,
  ]);

  const submission =
    !isSubmissionLoading &&
    submissionData?.submissions.filter(
      (sub) => !closedStatuses.includes(sub?.status),
    );

  const handleFindCandidates = useCallback(() => {
    navigate(`/inquiries/new-inquiry/${id}`);
  }, [navigate, id]);

  const handleManageSuppliers = () => {
    setIsOpenManageSuppliersModal(true);
  };

  const handleSubmit = useCallback(() => {
    const submissionData = !!submission && submission[0];

    if (!tabView.isCandidates || !submissionData) {
      return navigate(`/submissions/new-submission/${id}`);
    }

    return navigate(
      `/submissions/edit-submission/${submissionData.id}/${submissionData.status}`,
    );
  }, [submission, tabView, navigate, id]);

  const inquiryOrSubmissionToCreateChat = useMemo(() => {
    if (isMarketplace || isClient) return null;
    if (isSupplier) {
      const inquiry = inquiriesData?.inquiries[0];
      if (inquiry) return {inquiry};
      const submission = submissionData?.submissions.find(
        (submission) =>
          submission.status === INQUIRY_STATUS.in_review ||
          submission.status === INQUIRY_STATUS.responded,
      );
      if (submission) return {submission};
      // should return true if client already send any message to supplier's submission,
      // despite submission is in 'sent' status'. idk how to check that
      return null;
    }
  }, [
    inquiriesData?.inquiries,
    isClient,
    isMarketplace,
    isSupplier,
    submissionData?.submissions,
  ]);

  const handleSendMessage = useCallback(() => {
    if (isClient) return;
    if (isMarketplace) {
      navigate('/messages');
    } else {
      const inqOrSubm = inquiryOrSubmissionToCreateChat;
      if (inqOrSubm?.inquiry) {
        createInquiryChannel.mutate(inqOrSubm.inquiry.id, {
          onSuccess: ({data}) => {
            const channelId = data.id;
            navigate(`/messages?${MESSAGE_QUERIES.channelId}=${channelId}`);
          },
        });
      } else if (inqOrSubm?.submission) {
        createSubmissionChannel.mutate(inqOrSubm.submission.id, {
          onSuccess: ({data}) => {
            const channelId = data.id;
            navigate(`/messages?${MESSAGE_QUERIES.channelId}=${channelId}`);
          },
        });
      }
    }
  }, [
    isClient,
    isMarketplace,
    navigate,
    createInquiryChannel,
    createSubmissionChannel,
    inquiryOrSubmissionToCreateChat,
  ]);

  const handleAcceptInvitation = useCallback(() => {
    acceptDeclineRequisitionInvitation.mutate({
      id: getRequisitionInvitation.data?.requisitionInvitation[0].id,
      accepted: true,
    });
  }, [acceptDeclineRequisitionInvitation, getRequisitionInvitation.data]);

  const handleDeclineInvitation = useCallback(() => {
    acceptDeclineRequisitionInvitation.mutate(
      {
        id: getRequisitionInvitation.data?.requisitionInvitation[0].id,
        accepted: false,
      },
      {
        onSuccess: () => {
          navigate('/requisitions/browse');
        },
      },
    );
  }, [
    acceptDeclineRequisitionInvitation,
    getRequisitionInvitation.data,
    navigate,
  ]);

  const {
    handleShowNotes,
    isNotes,
    isShowNotes,
    total,
    response: notesResponse,
  } = useNotes('requisition_notes', 'requisition', requisition.id);

  const clientName = getUserName(requisition?.client?.name, 'CLI-', 'Client');

  const showCreateChannelButton = useMemo(() => {
    const inqOrSubm = inquiryOrSubmissionToCreateChat;
    const isAlreadyCreatedChannel = !!channel;
    return isAlreadyCreatedChannel || inqOrSubm;
  }, [channel, inquiryOrSubmissionToCreateChat]);

  const isShowFindCandidatesButton = useMemo(() => {
    if (isOpen && isClient && requisition.isDirectHire) return false;

    return isOpen && !isOnlyAcceptStaffingAgencies;
  }, [isOpen, isOnlyAcceptStaffingAgencies, isClient, requisition]);

  const isPendingSupplierInvite = useMemo(() => {
    return (
      getRequisitionInvitation.data?.requisitionInvitation?.[0]?.accepted ===
      null
    );
  }, [getRequisitionInvitation]);

  const isDisabledSubmitCandidateButton = useMemo(() => {
    if (requisition.isDirectHire) {
      return (
        supplierType?.includes(supplierTypes.recruitingAgency) &&
        isPendingSupplierInvite
      );
    }
    return (
      isPendingSupplierInvite &&
      (supplierType?.includes(supplierTypes.staffingAgency) ||
        !supplierType?.includes(supplierTypes.talentVendor))
    );
  }, [isPendingSupplierInvite, requisition.isDirectHire, supplierType]);

  const actions = useMemo(
    () => [
      {
        id: 1,
        isAvailable: isSupplier && isPendingSupplierInvite,
        action: (
          <Space size='middle'>
            <Button
              type='primary'
              className='btn-success'
              loading={acceptDeclineRequisitionInvitation.isLoading}
              onClick={handleAcceptInvitation}>
              Accept
            </Button>
            <Button
              type='primary'
              danger
              loading={acceptDeclineRequisitionInvitation.isLoading}
              onClick={handleDeclineInvitation}>
              Decline
            </Button>
          </Space>
        ),
      },
      {
        id: 2,
        isAvailable: isShowManageSupplierButton,
        action: (
          <Button type='primary' shape='round' onClick={handleManageSuppliers}>
            Manage Suppliers
          </Button>
        ),
      },
      {
        id: 3,
        isAvailable: isSupplier && isOpen,
        action: (
          <Button
            type='primary'
            shape='round'
            disabled={isDisabledSubmitCandidateButton}
            onClick={handleSubmit}>
            Submit Candidate(s)
          </Button>
        ),
      },
      {
        id: 4,
        isAvailable: !isSupplier && isShowFindCandidatesButton,
        action: (
          <Button type='primary' shape='round' onClick={handleFindCandidates}>
            Find Candidates
          </Button>
        ),
      },
      {
        id: 5,
        isAvailable: showCreateChannelButton,
        action: (
          <>
            <Tooltip overlayStyle={{fontSize: '12px'}} title={'Send message'}>
              <Button
                type='primary'
                shape='circle'
                onClick={handleSendMessage}
                icon={<RiMessageLine fontSize={16} />}
              />
            </Tooltip>
            <Divider type='vertical' style={{height: 28}} />
          </>
        ),
      },
      {
        id: 6,
        isAvailable: !isNotes,
        action: (
          <Tooltip
            overlayStyle={{fontSize: '12px'}}
            title={'Add Internal Note'}>
            <Button
              type='primary'
              shape='circle'
              onClick={handleShowNotes}
              icon={<NotesBtnIcon />}
            />
          </Tooltip>
        ),
      },
    ],
    [
      acceptDeclineRequisitionInvitation.isLoading,
      handleAcceptInvitation,
      handleDeclineInvitation,
      handleFindCandidates,
      handleSendMessage,
      handleShowNotes,
      handleSubmit,
      isDisabledSubmitCandidateButton,
      isNotes,
      isOpen,
      isPendingSupplierInvite,
      isShowFindCandidatesButton,
      isShowManageSupplierButton,
      isSupplier,
      showCreateChannelButton,
    ],
  );
  const isShowDivider = useMemo(
    () => actions.filter((item) => item.isAvailable).length !== 1,
    [actions],
  );

  if (isLoading || getRequisitionInvitation.isLoading) {
    return <AppLoader />;
  }

  return (
    <AppPageContainer
      header={
        <AppPageHeader
          backHeader
          category='Requisition'
          header='inner'
          title={isClient ? requisition?.jobTitle : clientName}
          subTitle={isSupplier && requisition?.jobTitle}
          count={
            <AppTag
              color={requisition.tagColor}
              shape='circle'
              label={requisition.statusName}
              className='tracking-normal'
            />
          }
          tabs={
            <AppDynamicTabs
              tabsData={getPrimaryTabs(
                isSupplier,
                isNotes,
                isSubmissions,
                isInquiries,
                isCandidates,
              )}
              scroll={true}
            />
          }
          backHeaderActions={
            isSupplier && (
              <Button
                type='text'
                shape='circle'
                loading={mutationBookmark.isLoading}
                onClick={() => mutationBookmark.mutate(requisition?.bookmarked)}
                className={clsx({bookmarked: requisition?.bookmarked})}
                icon={
                  requisition?.bookmarked ? (
                    <RiStarSFill className='text-xxl' />
                  ) : (
                    <RiStarLine className='text-xxl' />
                  )
                }
              />
            )
          }
          extra={
            <>
              {actions.map(
                (item, index) =>
                  item.isAvailable && (
                    <Fragment key={item.id}>
                      {item.action}

                      {index !== actions.length - 1 && isShowDivider && (
                        <Divider type='vertical' style={{height: 28}} />
                      )}
                    </Fragment>
                  ),
              )}
            </>
          }
        />
      }>
      <MainContent
        requisition={requisition}
        isShowNotes={isShowNotes}
        notesResponse={notesResponse}
        notesTotal={total}
        handleSetTabView={handleSetTabView}
        tabView={tabView}
      />
      {isShowManageSupplierButton && (
        <ManageSupplierModal
          isOpen={isOpenManageSuppliersModal}
          onClose={handleCloseModal}
          requisitionId={requisition.id}
          supplierType={
            requisition.isDirectHire
              ? supplierTypes.recruitingAgency
              : supplierTypes.staffingAgency
          }
        />
      )}
    </AppPageContainer>
  );
};

export default ViewRequisition;
