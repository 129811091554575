import AppCard from '@wieldy/components/AppCard';
import {Descriptions} from 'antd';
import {PAYMENT_TERMS, TRAVEL_REQUIREMENTS, WORKSITES} from 'app/constants';
import {getAddress, getYesOrNo} from 'app/utils/helpers/DataFormatHelper';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';

const JobDetailsView = () => {
  const {jobDetails: details} = useSelector(({placement}) => placement);
  return (
    <React.Fragment>
      <AppCard>
        <Descriptions
          colon={false}
          labelStyle={{width: '50%'}}
          className='description-root mb-5'
          column={{xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1}}>
          <Descriptions.Item label='Work Model'>
            {WORKSITES[details?.job_worksite]}
          </Descriptions.Item>
          <Descriptions.Item label='Job Location'>
            {getAddress(details)}
          </Descriptions.Item>
          <Descriptions.Item label='Part Time'>
            {getYesOrNo(details?.part_time)}
          </Descriptions.Item>
          <Descriptions.Item label='Travel Required'>
            {TRAVEL_REQUIREMENTS[details?.job_travel_requirements]}
          </Descriptions.Item>

          <Descriptions.Item label='FLSA Eligible'>
            {details?.job_flsa_eligible ? 'Exempt' : 'Non-Exempt'}
          </Descriptions.Item>

          <Descriptions.Item label='Payment Terms'>
            {PAYMENT_TERMS[details?.job_payment_term]}
          </Descriptions.Item>
          <Descriptions.Item label='Charge Tax'>
            {getYesOrNo(details?.charge_tax)}
          </Descriptions.Item>
          <Descriptions.Item label='Country Tax'>
            {`${details?.tax_country || 0}%`}
          </Descriptions.Item>
          <Descriptions.Item label='State Tax'>
            {`${details?.tax_state || 0}%`}
          </Descriptions.Item>
          <Descriptions.Item label='City Tax' span={1}>
            {`${details?.tax_city || 0}%`}
          </Descriptions.Item>
        </Descriptions>
      </AppCard>
    </React.Fragment>
  );
};

JobDetailsView.propTypes = {
  details: PropTypes.object,
};

export default JobDetailsView;
