import {supplierTypes as SUPPLIER_TYPES} from '../../app/constants/supplier-types';

export const getRegionArgs = (supplierType) => {
  if (!supplierType) {
    return {
      isBoth: false,
      isService: false,
      isJob: false,
    };
  }

  return {
    isService: supplierType?.includes(SUPPLIER_TYPES.talentVendor),
  };
};
