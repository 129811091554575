import {calcAmountByPercent} from './CalcAmountByPercent';
import {formatBillingRateRage} from './DataFormatHelper';

export const calcRecruitmentFee = ({
  base,
  min,
  max,
  fee,
  isFixed,
  isTemporary,
  diff,
}) => {
  if (isFixed) return formatBillingRateRage(fee);

  if (base) {
    if (isTemporary) {
      base = base * 2080;
    }

    if (diff) {
      base = ((base * 2080) / 365) * diff;
    }
    return formatBillingRateRage(calcAmountByPercent(base, fee));
  }

  if (isTemporary) {
    min = min * 2080;
    max = max * 2080;
  }

  if (diff) {
    min = ((min * 2080) / 365) * diff;
    max = ((max * 2080) / 365) * diff;
  }

  const minAmount = calcAmountByPercent(min, fee);
  const maxAmount = calcAmountByPercent(max, fee);

  return formatBillingRateRage(base, minAmount, maxAmount);
};
