import accounting from 'accounting';
import {DATE_FORMATS} from 'app/config/app';
import {COUNTRIES, US_STATES} from 'app/constants';
import moment from 'moment';
import {formatPhoneNumberIntl} from 'react-phone-number-input';

export const getFullName = (object, isFileName) => {
  if (!object) {
    return '';
  }

  if (isFileName) {
    return `${object.first_name || object.firstName || ''}-${
      object.last_name || object.lastName || ''
    }`?.replaceAll('.', '');
  }

  return `${object.first_name || object.firstName || ''} ${
    object.last_name || object.lastName || ''
  }`;
};

export const getAddress = (object) => {
  if (!object) {
    return '';
  }

  const initCountry = object.country || object.job_country;

  const isUSA =
    initCountry === 'united_states' ||
    initCountry === COUNTRIES['united_states'];

  const country = initCountry && (COUNTRIES[initCountry] || initCountry);

  const usState = object.us_state || object.united_states;

  const state = usState && (US_STATES[usState] || usState);
  const city = object?.city;

  const isComma = !!city && (!!country || !!state);

  const result = `${city || ''}${isComma ? ',' : ''} ${
    isUSA ? state || country || '' : country || ''
  }`;
  return result;
};

export const getPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return '';
  return formatPhoneNumberIntl(
    phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`,
  );
};

export const getPhoneNumberForm = (phoneNumber) => {
  return phoneNumber.indexOf('+') > -1
    ? phoneNumber.replace('+', '')
    : phoneNumber;
};

export const formatAmount = (amount) => {
  if (!amount) {
    return `$${Number(0).toFixed(2)}`;
  }
  return `$${Number(amount)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

export const formatISO = (date) => {
  if (!date) {
    return undefined;
  }
  return moment(date).toISOString();
};

export const formatSecondsToDate = (
  seconds,
  format = 'MMM D, YYYY [at] h:mm a',
) => {
  if (!seconds) {
    return '';
  }
  const date = new Date(seconds * 1000);
  return moment(date).format(format);
};

export const formatDate = (dateString, format = DATE_FORMATS['/']) => {
  if (!dateString) {
    return '';
  }
  if (format) {
    return typeof dateString === 'number'
      ? moment.unix(dateString).format(format)
      : moment(dateString).format(format);
  }
  return dateString;
};

export const formatUnixDate = (timestamp, format = 'YYYY-MM-DD') => {
  return moment.unix(timestamp).format(format);
};

export const formatBillingRateRage = (
  billingRate,
  billingMin,
  billingMax,
  isCurrency = true,
  isExactFirst = false,
) => {
  if (!billingRate && !billingMin && !billingMax) {
    return 'N/A';
  }

  if (isExactFirst && billingRate) {
    return `$${accounting.formatMoney(Number(billingRate), '', 0)} ${
      isCurrency ? 'USD' : ''
    }`;
  }
  if (billingMin && billingMax) {
    return `$${accounting.formatMoney(
      Math.round(Number(billingMin)),
      '',
      0,
    )} - $${accounting.formatMoney(Math.round(Number(billingMax)), '', 0)} ${
      isCurrency ? 'USD' : ''
    }`;
  }

  if (billingRate) {
    return `$${accounting.formatMoney(Number(billingRate), '', 0)} ${
      isCurrency ? 'USD' : ''
    }`;
  }
  return `$${accounting.formatMoney(Number(billingMin || billingMax), '', 0)} ${
    isCurrency ? 'USD' : ''
  }`;
};

export const formatBillingRate = (billingRate, isCurrency) => {
  if (!billingRate) {
    return '';
  }

  if (isCurrency) {
    return `$${Number(billingRate).toFixed(2)} / hour`;
  }

  return Number(billingRate).toFixed(2);
};

export const formatPayUnits = (payUnits = 0, fractionDigits = 3) => {
  return Number(payUnits / 60).toFixed(fractionDigits);
};

export const formatRelativeDate = (timestamp) => {
  const date = moment.unix(timestamp);
  return moment(date).fromNow();
};

export const formatTimeType = (timeType) => {
  switch (timeType) {
    case 'regular':
      return 'Regular Time Hours';
    case 'overtime':
      return 'Overtime Hours';
    default:
      return 'NA';
  }
};

export const splitString = (str = '', size) => {
  const regex = new RegExp(String.raw`\S.{1,${size}}\S(?= |$)`, 'g');
  return str.match(regex);
};

export const formatToDiff = (value) => {
  const now = moment();
  const then = moment().add(value, 'minutes');

  const days = then.diff(now, 'days');
  const week = then.diff(now, 'week');
  const month = then.diff(now, 'months');
  if (month) {
    return `${month} ${month === 1 ? 'month' : 'months'}`;
  }
  if (week) {
    return `${week} ${week === 1 ? 'week' : 'weeks'}`;
  }
  if (days) {
    return `${days} ${days === 1 ? 'day' : 'days'}`;
  }
};

export const formatHoursFloat = (minutes) => {
  return Math.round((minutes / 60 + Number.EPSILON) * 1000) / 1000;
};

export const formatMinutes = (value) => {
  if (!value) {
    return '0h';
  }
  const hours = Math.floor(value / 60);
  const minutes = Math.floor(value % 60);
  if (!hours) {
    return `${minutes}m`;
  }
  return `${hours}h ${minutes}m`;
};

export const formatTableDate = (value) => {
  if (!value) {
    return 'N/A';
  }
  return moment(value).format('MM/DD/YYYY');
};

export const getYesOrNo = (bool) => (bool ? 'Yes' : 'No');

export const daysFromDate = (date) => {
  const today = moment().format('YYYY-MM-DD');
  const dateF = moment(date).format('YYYY-MM-DD');
  const start = moment(today, 'YYYY-MM-DD');
  const end = moment(dateF, 'YYYY-MM-DD');
  return moment.duration(start.diff(end)).asDays();
};

export const daysDifference = (fromDate, toDate) => {
  const dateF = moment(fromDate).format('YYYY-MM-DD');
  const dateT = moment(toDate).format('YYYY-MM-DD');
  const start = moment(dateF, 'YYYY-MM-DD');
  const end = moment(dateT, 'YYYY-MM-DD');
  return Math.round(moment.duration(end.diff(start)).asDays());
};

export const getDuration = (value, key = 'days') => {
  if (!value) {
    return '';
  }
  const duration = moment.duration(value, key);

  // Format the duration in weeks and months
  const days = Math.floor(duration.asDays());
  const weeks = Math.floor(duration.asWeeks());
  const months = Math.floor(duration.asMonths());
  const years = Math.floor(duration.asYears());

  if (years > 0 && months > 0) {
    return `${years} yrs ${months - years * 12} mos`;
  }
  if (months > 0) {
    return `${months} mos`;
  }
  if (weeks > 0) {
    return `${weeks} ${weeks === 1 ? 'week' : 'weeks'}`;
  }
  if (days > 0) {
    return `${days} ${days === 1 ? 'day' : 'days'}`;
  }
};

export const getProcessedDate = (date) => {
  return date && date instanceof moment
    ? date
    : typeof date === 'number'
    ? moment.unix(date)
    : undefined;
};
