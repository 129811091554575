import AppCard from '@wieldy/components/AppCard';
import AppFormWrapper, {
  AppFormActions,
  AppFormBody,
} from '@wieldy/components/AppFormWrapper';
import AppInputNumber from '@wieldy/components/dataEntry/AppInputNumber';
import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Button, Form, Radio} from 'antd';
import {
  feeFixedValidator,
  feePercentageValidator,
} from 'app/utils/helpers/RecruitmentFeeValidators';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';

import {DATE_FORMATS} from '../../../../config/app';
import {
  GUARANTEE_PERIOD,
  GUARANTEE_PERIOD_ACTION,
  GUARANTEE_PERIOD_VALUES,
  RECRUITMENT_FEE_OPTIONS,
} from '../../../../constants';
import {formatSecondsToDate} from '../../../../utils/helpers/DataFormatHelper';
import {getOptionsFromObject} from '../../../../utils/helpers/GetOptionsFromObject';
import {allowPositiveExactNumber} from '../../../../utils/helpers/preventNegativeNumber';

const InformationForm = ({
  initialValues: {
    base,
    min,
    max,
    isFeeContract,
    start_date,
    end_date,
    ...initialValues
  },
  onSave,
  onContinue,
}) => {
  const [form] = Form.useForm();

  const feeType = Form.useWatch('recruitment_fee_structure', form);
  const guaranteePeriod = Form.useWatch('guarantee_period', form);

  const onFormSubmit = (formData) => {
    onSave(formData);
    onContinue();
  };

  const handleChangeFeeType = React.useCallback(() => {
    form.setFieldValue('recruitment_fee', undefined);
  }, [form]);

  const isPercent = feeType === 'percentage_of_salary';

  const startDate = moment(formatSecondsToDate(start_date), DATE_FORMATS.md);
  const endDate = end_date
    ? moment(formatSecondsToDate(end_date), DATE_FORMATS.md)
    : null;
  const diff = endDate ? endDate.diff(startDate, 'days') : null;

  useEffect(() => {
    if (isFeeContract && GUARANTEE_PERIOD_VALUES[guaranteePeriod] > diff) {
      form.setFieldValue('guarantee_period', undefined);
    }
  }, [diff, form, guaranteePeriod, isFeeContract]);

  const periodOptions = getOptionsFromObject(GUARANTEE_PERIOD).map((el) => {
    if (isFeeContract && GUARANTEE_PERIOD_VALUES[el.value] > diff) {
      return {...el, disabled: true};
    }
    return el;
  });

  return (
    <AppCard>
      <AppFormWrapper>
        <Form
          form={form}
          layout='vertical'
          onFinish={onFormSubmit}
          autoComplete='off'
          initialValues={initialValues}>
          <AppFormBody>
            <Form.Item
              label='Recruitment Fee Structure'
              name='recruitment_fee_structure'
              rules={[
                {
                  required: true,
                },
              ]}>
              <Radio.Group
                options={RECRUITMENT_FEE_OPTIONS}
                onChange={handleChangeFeeType}
              />
            </Form.Item>
            <Form.Item
              name='recruitment_fee'
              style={{width: '280px'}}
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Fee'),
                },
                {
                  validator: isPercent
                    ? feePercentageValidator
                    : feeFixedValidator({
                        diff,
                        baseSalary: base,
                        baseSalaryMax: max,
                        baseSalaryMin: min,
                      }),
                },
              ]}>
              <AppInputNumber
                placeholder={isPercent ? 'Enter percentage' : 'Enter amount'}
                prefix={isPercent ? '' : '$'}
                addonAfter={isPercent ? '%' : 'USD'}
                type='number'
                onKeyDown={allowPositiveExactNumber}
                pattern='[0-9]+([\.,][0-9]+)?'
                step='any'
                style={{width: '100%'}}
                controls={false}
              />
            </Form.Item>
            <Form.Item
              style={{width: 280}}
              name='guarantee_period'
              label='Guarantee Period'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Guarantee period'),
                },
              ]}>
              <AppSelect
                placeholder={getSelectPlaceHolder('period')}
                options={periodOptions}
                allowClear={true}
              />
            </Form.Item>
            <Form.Item
              style={{width: 280}}
              name='guarantee_period_action'
              label='Guarantee Action'
              rules={[
                {
                  required: true,
                  message: getRequiredMessage('Guarantee Action'),
                },
              ]}>
              <AppSelect
                placeholder={getSelectPlaceHolder('action')}
                options={getOptionsFromObject(GUARANTEE_PERIOD_ACTION)}
                allowClear={true}
              />
            </Form.Item>
          </AppFormBody>

          <AppFormActions>
            <Button type='primary' htmlType='submit'>
              Continue
            </Button>
          </AppFormActions>
        </Form>
      </AppFormWrapper>
    </AppCard>
  );
};

InformationForm.propTypes = {
  initialValues: PropTypes.object,
  onSave: PropTypes.func,
  onContinue: PropTypes.func,
  isFeeContract: PropTypes.string,
};

export default InformationForm;
