import {JOB_FUNCTIONS_MAPPED} from '@register-app/constants/job-functions';
import AppCard from '@wieldy/components/AppCard';
import {Descriptions} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {ACCEPT_CANDIDATES_OPTIONS} from '../../../../constants/work-types';
import MultipleValuePreview from '../../../components/MultipleValuePreview';

const JobInformation = ({requisitionModal}) => {
  return (
    <AppCard style={{maxWidth: 680}}>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Job Title'>
          {requisitionModal.jobTitle}
        </Descriptions.Item>
        <Descriptions.Item label='Job Function'>
          {JOB_FUNCTIONS_MAPPED[requisitionModal.job_function]}
        </Descriptions.Item>
        <Descriptions.Item label='Type'>
          {requisitionModal.workType}
        </Descriptions.Item>
        {requisitionModal?.contractType && (
          <Descriptions.Item label='Contract Type'>
            {requisitionModal.contractType}
          </Descriptions.Item>
        )}
        {requisitionModal.accept_candidates_from?.length && (
          <Descriptions.Item label='Accept Candidates From'>
            <MultipleValuePreview
              type={requisitionModal.accept_candidates_from}
              source={ACCEPT_CANDIDATES_OPTIONS}
            />
          </Descriptions.Item>
        )}
        <Descriptions.Item label='Priority'>
          {requisitionModal.jobPriority}
        </Descriptions.Item>
        <Descriptions.Item label='Openings'>
          {requisitionModal.openings}
        </Descriptions.Item>
        <Descriptions.Item label='Part Time'>
          {requisitionModal.partTime}
        </Descriptions.Item>
        <Descriptions.Item label='Recruiter'>
          {requisitionModal.recruiter.name}
        </Descriptions.Item>
        <Descriptions.Item>{}</Descriptions.Item>
        {requisitionModal.hiringManager && (
          <>
            <Descriptions.Item label='Hiring Manager'>
              {requisitionModal.hiringManager?.name}
            </Descriptions.Item>
            <Descriptions.Item>{}</Descriptions.Item>
          </>
        )}
      </Descriptions>
    </AppCard>
  );
};

JobInformation.propTypes = {
  requisitionModal: PropTypes.object,
};

export default JobInformation;
