import Label from '@register-app/components/additional/label';
import MultipleCheckboxView from '@register-app/components/additional/multiple-checkbox-view';
import ShowRegion from '@register-app/components/additional/show-region';
import {getDate} from '@register-app/components/views/diversity-information-view';
import {
  DIVERSITY_ENTITY,
  DIVERSITY_PRACTICES,
  SPECIFIC_GROUP,
} from '@register-app/constants/company-diversity-statuses';
import {INDUSTRY_FOCUS} from '@register-app/constants/industries-data';
import {IS_SUBJECT_LABEL_FOR_EDIT} from '@register-app/constants/is-subject-options';
import {JOB_FUNCTIONS} from '@register-app/constants/job-functions';
import {PLATFORMS} from '@register-app/constants/platforms';
import {SERVICE_FOCUS} from '@register-app/constants/service-focus';
import {getRecruitingServices} from '@register-app/utils/get-recruiting-services';
import {getRegionArgs} from '@register-app/utils/get-region-args';
import AppTag from '@wieldy/components/AppTag/AppTag';
import {capitalizedString} from '@wieldy/utils/helpers/StringHelper';
import {Avatar, Col, Descriptions, Row, Typography} from 'antd';
import {COUNTRIES, US_STATES} from 'app/constants';
import {
  supplierTypes as SUPPLIER_TYPES,
  supplierTypesNames,
} from 'app/constants/supplier-types';
import MultipleValuePreview from 'app/shared/components/MultipleValuePreview';
import UserPopover from 'app/shared/components/UserPopover';
import PropTypes from 'prop-types';
import React from 'react';
import {RiBuildingLine} from 'react-icons/ri';

import {InsuranceSupplierState} from '../../../../../requisitions/ViewRequisition/constatns';

const getData = (data, placeholder) => {
  if (!data) {
    return null;
  }
  const entries = Object.entries(data);
  return entries
    .filter(([, {value}]) => !!value)
    .map(([key, data]) => {
      return {
        ...data,
        value: placeholder[key]?.value,
      };
    });
};
const SupplierCompanyDetail = ({company, children}) => {
  const industries = getData(
    company?.specialization_industry_focuses,
    INDUSTRY_FOCUS,
  );
  const jobFunctions = getData(
    company?.specialization_job_functions,
    JOB_FUNCTIONS,
  );
  const serviceRegions = getData(
    company?.specialization_service_focuses,
    SERVICE_FOCUS,
  );

  const isAgency =
    company?.type?.includes(SUPPLIER_TYPES.recruitingAgency) ||
    company?.type?.includes(SUPPLIER_TYPES.staffingAgency);

  const {isService} = getRegionArgs(company?.type);
  const recServices = getRecruitingServices(company);

  return (
    <div id='supplier-data'>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-6 '
        column={{xxl: 3, xl: 3, lg: 3, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Company Name'>
          {company?.name}
        </Descriptions.Item>
        <Descriptions.Item label='DBA Company Name'>
          {company?.company_dba_name || 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label='Supplier type'>
          <MultipleValuePreview
            type={company?.type}
            source={supplierTypesNames}
          />
        </Descriptions.Item>
        <Descriptions.Item label='Company Type'>
          {company?.companyType}
        </Descriptions.Item>
        <Descriptions.Item label='Company Size'>
          {company?.companySize}
        </Descriptions.Item>
        <Descriptions.Item label='Engagement Status'>
          {company?.statusName}
        </Descriptions.Item>
        <Descriptions.Item label='Website'>
          {company.website && (
            <Typography.Link
              className={'font-semibold'}
              target='_blank'
              href={company.website}>
              {company.website.replace(/(^\w+:|^)\/\//, '')}
            </Typography.Link>
          )}
        </Descriptions.Item>
        <Descriptions.Item label='LinkedIn Page'>
          {company.linkedin && (
            <Typography.Link
              className={'font-semibold'}
              target='_blank'
              href={company.linkedin}>
              {company.linkedin.replace(/(^\w+:|^)\/\//, '') || 'N/A'}
            </Typography.Link>
          )}
        </Descriptions.Item>
        <Descriptions.Item label='Years in Business'>
          {company?.years_in_business || 0} years
        </Descriptions.Item>
        <Descriptions.Item label='Tax Jurisdiction'>
          {IS_SUBJECT_LABEL_FOR_EDIT[company?.tax_jurisdiction] || 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label='Company Representative'>
          <UserPopover user={company.companyRepresentative} />
        </Descriptions.Item>
        <Descriptions.Item label='Account Manager'>
          <UserPopover user={company.accountManager} />
        </Descriptions.Item>
        {isAgency && (
          <>
            <Descriptions.Item label='Recruiting/Staffing Services'>
              <MultipleCheckboxView item={recServices} hideTitle />
            </Descriptions.Item>
            <Descriptions.Item label='Placement Type Ratios'>
              <div className='d-flex flex-column'>
                <Typography.Paragraph>
                  Contingent placement fee [
                  {company?.placement_contingent_placement_fee || 0}%]
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Retained fee [{company?.placement_retained_fee || 0}%]
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Temporary [{company?.placement_temporary_hires || 0}%]
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Temp-to-hire [{company?.placement_temp_to_perm || 0}%]
                </Typography.Paragraph>
              </div>
            </Descriptions.Item>
            <Descriptions.Item label='# of Recruiters'>
              {company?.number_of_recruiters}
            </Descriptions.Item>
            <Descriptions.Item label='ATS Used by Company'>
              {PLATFORMS[company?.ats_used_by_company] || 'N/A'}
            </Descriptions.Item>
          </>
        )}

        <Descriptions.Item label='Has Insurance'>
          {company.has_insurance
            ? InsuranceSupplierState.yes
            : InsuranceSupplierState.no}
        </Descriptions.Item>
        <Descriptions.Item label='Joined'>
          {company?.createdAt}
        </Descriptions.Item>
        <Descriptions.Item label='Account Status' span={1}>
          {capitalizedString(company?.account_status)}
        </Descriptions.Item>
        <Descriptions.Item label='Company ID'>{company?.hid}</Descriptions.Item>
        <Descriptions.Item label='Updated' span={1}>
          {company?.updatedAt}
        </Descriptions.Item>
      </Descriptions>
      <Typography.Title level={3} className={'mb-3'}>
        Diversity Information
      </Typography.Title>
      <Descriptions
        column={1}
        colon={false}
        labelStyle={{width: '220px'}}
        className='description-root mb-6'>
        {!!company?.diversity_statuses?.length && (
          <Descriptions.Item label='Diversity Status' span={2}>
            <div>
              {company?.diversity_statuses?.map((el) => (
                <Typography.Paragraph key={el.key}>{`${
                  DIVERSITY_ENTITY[el.key]
                } [${el.granting_authority} | ${
                  el.certificate_number
                } | ${getDate(
                  el.dates_of_certification,
                )}]`}</Typography.Paragraph>
              ))}
            </div>
          </Descriptions.Item>
        )}
        <Descriptions.Item label='Diversity Sourcing Practices' span={2}>
          {DIVERSITY_PRACTICES[company?.diversity_sourcing_practices?.value]}
          {!!company?.diversity_sourcing_practices?.description?.length &&
            '[' +
              company?.diversity_sourcing_practices?.description
                .map((el) => SPECIFIC_GROUP[el])
                .join(', ') +
              ']'}
        </Descriptions.Item>
      </Descriptions>
      <Typography.Title level={3} className={'mb-3'}>
        Specializations and Service Regions
      </Typography.Title>
      <Row colon={false} className='mb-5'>
        <Col md={8}>
          <Label text='Job Functions' />
          <div className='d-flex flex-column'>
            {jobFunctions?.map(({value, items}) => (
              <ShowRegion key={value} name={value} items={items} />
            ))}
          </div>
        </Col>
        {isService && (
          <Col md={8}>
            <Label text='Service Focus' />
            <div className='d-flex flex-column'>
              {serviceRegions?.map(({value, items}) => (
                <ShowRegion key={value} name={value} items={items} />
              ))}
            </div>
          </Col>
        )}
        <Col md={8}>
          <Label text='Industry Focus' />
          <div className='d-flex flex-column'>
            {industries?.map(({value, items}) => (
              <ShowRegion key={value} name={value} items={items} />
            ))}
          </div>
        </Col>
      </Row>
      <Row colon={false} className='mb-5'>
        <Col md={8}>
          <Label text='Service Regions' />
          <div className='d-flex gap-2'>
            {company?.service_region_countries?.map((el) => (
              <AppTag
                key={el}
                className={'border-none'}
                shape={'square'}
                label={COUNTRIES[el]}
              />
            ))}
          </div>
        </Col>
        {!!company?.service_region_us_states?.length && (
          <Col md={8}>
            <Label text='Service Regions' />
            <div className='d-flex gap-2'>
              {company?.service_region_us_states?.map((el) => (
                <AppTag
                  key={el}
                  className={'border-none'}
                  shape={'square'}
                  label={US_STATES[el]}
                />
              ))}
            </div>
          </Col>
        )}
      </Row>
      {company.about && (
        <div className={'mb-8'}>
          <Typography.Title level={4}>About</Typography.Title>
          <Typography.Paragraph>
            <div dangerouslySetInnerHTML={{__html: company.about}} />
          </Typography.Paragraph>
        </div>
      )}

      <div className={'mb-10'}>
        <Typography.Title level={4}>Company Logo</Typography.Title>
        <div className='mb-3'>
          <Avatar
            shape='square'
            src={company.logo}
            size={60}
            icon={<RiBuildingLine fontSize={24} />}
          />
        </div>
      </div>
      {children}
    </div>
  );
};

SupplierCompanyDetail.propTypes = {
  company: PropTypes.object,
  children: PropTypes.node,
};

export default SupplierCompanyDetail;
