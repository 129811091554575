import {DATE_FORMATS} from '../config/app';
import InquiryModal from './InquiryModal';

class SubmissionModal extends InquiryModal {
  constructor(submission = {}) {
    super(submission);
    this.aboutDates = {
      received: this.formatDate(submission?.created_at, DATE_FORMATS.lg),
      updated: this.formatDate(submission?.updated_at, DATE_FORMATS.lg),
    };
    this.accompanying_message = submission?.accompanying_message || null;
    this.created_from_job_board = !!submission?.created_from_job_board;
  }
}

export default SubmissionModal;
