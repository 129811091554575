import MultipleCheckboxView from '@register-app/components/additional/multiple-checkbox-view';
import ShowRegion from '@register-app/components/additional/show-region';
import {getDate} from '@register-app/components/views/diversity-information-view';
import {
  DIVERSITY_ENTITY,
  DIVERSITY_PRACTICES,
  SPECIFIC_GROUP,
} from '@register-app/constants/company-diversity-statuses';
import {INDUSTRY_FOCUS} from '@register-app/constants/industries-data';
import {JOB_FUNCTIONS} from '@register-app/constants/job-functions';
import {SERVICE_FOCUS} from '@register-app/constants/service-focus';
import {getRecruitingServices} from '@register-app/utils/get-recruiting-services';
import {getRegionArgs} from '@register-app/utils/get-region-args';
import AppCard from '@wieldy/components/AppCard';
import AppTag from '@wieldy/components/AppTag/AppTag';
import AppTags from '@wieldy/components/AppTags';
import {Descriptions, Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import {COUNTRIES, US_STATES} from '../../../constants';
import {
  supplierTypes as SUPPLIER_TYPES,
  supplierTypesNames,
} from '../../../constants/supplier-types';
import {InsuranceSupplierState} from '../../../pages/main/requisitions/ViewRequisition/constatns';

const getData = (data, placeholder) => {
  if (!data) {
    return null;
  }
  const entries = Object.entries(data);
  return entries
    .filter(([, {value}]) => !!value)
    .map(([key, data]) => {
      return {
        ...data,
        value: placeholder[key]?.value,
      };
    });
};

const OverviewCard = ({supplier}) => {
  const recServices = getRecruitingServices(supplier);
  const isAgency =
    supplier?.type?.includes(SUPPLIER_TYPES.recruitingAgency) ||
    supplier?.type?.includes(SUPPLIER_TYPES.staffingAgency);

  const industries = getData(
    supplier?.specialization_industry_focuses,
    INDUSTRY_FOCUS,
  );
  const jobFunctions = getData(
    supplier?.specialization_job_functions,
    JOB_FUNCTIONS,
  );
  const serviceRegions = getData(
    supplier?.specialization_service_focuses,
    SERVICE_FOCUS,
  );

  const {isService} = getRegionArgs(supplier?.type);

  return (
    <AppCard
      id='supplier-data'
      title='Overview'
      headStyle={{borderBottom: 0}}
      bodyStyle={{paddingTop: 8}}>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Relationship Status'>
          {supplier?.relationStatus ?? 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label='DBA Company Name'>
          {supplier?.company_dba_name ?? 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label='Supplier Type'>
          <AppTags items={supplier?.type.map((t) => supplierTypesNames[t])} />
        </Descriptions.Item>
        <Descriptions.Item label='Company Type'>
          {supplier?.companyType}
        </Descriptions.Item>
        <Descriptions.Item label='Company Size'>
          {supplier?.companySize}
        </Descriptions.Item>
        <Descriptions.Item label='# of Deployed Resources'>
          {supplier?.engagements ?? 0}
        </Descriptions.Item>
        {supplier?.website && (
          <Descriptions.Item label='Website'>
            <Typography.Link
              href={supplier?.website}
              target='_blank'
              className={'font-semibold'}>
              {supplier.website.replace(/(^\w+:|^)\/\//, '')}
            </Typography.Link>
          </Descriptions.Item>
        )}
        {supplier?.linkedin && (
          <Descriptions.Item label='Company LinkedIn Page'>
            <Typography.Link
              href={supplier.linkedin}
              target='_blank'
              className={'font-semibold'}>
              {supplier?.linkedin.replace(/(^\w+:|^)\/\//, '')}
            </Typography.Link>
          </Descriptions.Item>
        )}
        {isAgency && (
          <>
            <Descriptions.Item label='Recruiting/Staffing Services'>
              <MultipleCheckboxView item={recServices} hideTitle />
            </Descriptions.Item>
            <Descriptions.Item label='Placement Type Ratios'>
              <div className='d-flex flex-column'>
                <Typography.Paragraph>
                  Contingent placement fee [
                  {supplier?.placement_contingent_placement_fee || 0}%]
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Retained fee [{supplier?.placement_retained_fee || 0}%]
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Temporary [{supplier?.placement_temporary_hires || 0}%]
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Temp-to-hire [{supplier?.placement_temp_to_perm || 0}%]
                </Typography.Paragraph>
              </div>
            </Descriptions.Item>
            <Descriptions.Item label='# of Recruiters'>
              {supplier?.number_of_recruiters}
            </Descriptions.Item>
          </>
        )}

        <Descriptions.Item label='Joined'>
          {supplier?.createdAt}
        </Descriptions.Item>

        <Descriptions.Item label='Has Insurance'>
          {supplier?.has_insurance
            ? InsuranceSupplierState.yes
            : InsuranceSupplierState.no}
        </Descriptions.Item>
        <Descriptions.Item label='Company ID'>
          {supplier?.hid}
        </Descriptions.Item>

        <Descriptions.Item label='Updated' span={1}>
          {supplier?.updatedAt}
        </Descriptions.Item>
      </Descriptions>
      <Typography.Title level={3} className={'mb-3'}>
        Diversity Information
      </Typography.Title>
      <Descriptions
        column={1}
        colon={false}
        labelStyle={{width: '220px'}}
        className='description-root mb-6'>
        {!!supplier?.diversity_statuses?.length && (
          <Descriptions.Item label='Diversity Status' span={2}>
            <div>
              {supplier?.diversity_statuses?.map((el) => (
                <Typography.Paragraph key={el.key}>{`${
                  DIVERSITY_ENTITY[el.key]
                } [${el.granting_authority} | ${
                  el.certificate_number
                } | ${getDate(
                  el.dates_of_certification,
                )}]`}</Typography.Paragraph>
              ))}
            </div>
          </Descriptions.Item>
        )}
        <Descriptions.Item label='Diversity Sourcing Practices' span={2}>
          {DIVERSITY_PRACTICES[supplier?.diversity_sourcing_practices?.value]}
          {!!supplier?.diversity_sourcing_practices?.description?.length &&
            '[' +
              supplier?.diversity_sourcing_practices?.description
                .map((el) => SPECIFIC_GROUP[el])
                .join(', ') +
              ']'}
        </Descriptions.Item>
      </Descriptions>
      <Typography.Title level={3} className={'mb-3'}>
        Specializations and Service Regions
      </Typography.Title>
      <Descriptions
        colon={false}
        labelStyle={{width: '50%'}}
        className='description-root mb-4'
        column={{xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1}}>
        <Descriptions.Item label='Job Functions'>
          <div className='d-flex flex-column'>
            {jobFunctions?.map(({value, items}) => (
              <ShowRegion key={value} name={value} items={items} />
            ))}
          </div>
        </Descriptions.Item>

        {isService && (
          <Descriptions.Item label='Service Focus'>
            <div className='d-flex flex-column'>
              {serviceRegions?.map(({value, items}) => (
                <ShowRegion key={value} name={value} items={items} />
              ))}
            </div>
          </Descriptions.Item>
        )}
        <Descriptions.Item label='Industry Focus'>
          <div className='d-flex flex-column'>
            {industries?.map(({value, items}) => (
              <ShowRegion key={value} name={value} items={items} />
            ))}
          </div>
        </Descriptions.Item>
        <Descriptions.Item label='Service Regions' span={1}>
          <div className='d-flex flex-gap-2 flex-wrap'>
            {supplier?.service_region_countries?.map((el) => (
              <AppTag
                key={el}
                className={'border-none'}
                shape={'square'}
                label={COUNTRIES[el]}
              />
            ))}
          </div>
        </Descriptions.Item>
        {!!supplier?.service_region_us_states?.length && (
          <Descriptions.Item label='Service States' span={1}>
            <div className='d-flex flex-gap-2 flex-wrap'>
              {supplier?.service_region_us_states?.map((el) => (
                <AppTag
                  key={el}
                  className={'border-none'}
                  shape={'square'}
                  label={US_STATES[el]}
                />
              ))}
            </div>
          </Descriptions.Item>
        )}
      </Descriptions>

      {supplier?.about && (
        <Typography.Paragraph>
          <Typography.Title level={4}>About</Typography.Title>
          <Typography.Paragraph>
            <div dangerouslySetInnerHTML={{__html: supplier?.about}} />
          </Typography.Paragraph>
        </Typography.Paragraph>
      )}
    </AppCard>
  );
};

OverviewCard.propTypes = {
  supplier: PropTypes.object,
};

export default React.memo(OverviewCard);
