import {calcAmountByPercent} from './CalcAmountByPercent';
import {formatBillingRateRage} from './DataFormatHelper';

export const feePercentageValidator = (_, value) => {
  if (value === null) return Promise.resolve();

  if (value < 10 || value > 50) {
    return Promise.reject('Percentage should be from 10% to 50%');
  }

  return Promise.resolve();
};

export const feeFixedValidator =
  ({baseSalary, baseSalaryMin, baseSalaryMax, diff}) =>
  (_, value) => {
    if (value === null) return Promise.resolve();

    if (baseSalary) {
      const salary = diff ? (baseSalary / 365) * diff : baseSalary;
      const minValue = calcAmountByPercent(salary, 10);
      const maxValue = calcAmountByPercent(salary, 50);

      if (value < minValue || value > maxValue) {
        return Promise.reject(
          `Fee should be from ${formatBillingRateRage(
            minValue,
            null,
            null,
            false,
          )} to ${formatBillingRateRage(maxValue, null, null, false)}`,
        );
      }

      return Promise.resolve();
    }

    const salaryMin = diff ? (baseSalaryMin / 365) * diff : baseSalaryMin;
    const salaryMax = diff ? (baseSalaryMax / 365) * diff : baseSalaryMax;
    const minRangeValue = calcAmountByPercent(salaryMin, 10);
    const maxRangeValue = calcAmountByPercent(salaryMax, 50);

    if (value < minRangeValue || value > maxRangeValue) {
      return Promise.reject(
        `Fee should be from ${formatBillingRateRage(
          minRangeValue,
          null,
          null,
          false,
        )} to ${formatBillingRateRage(maxRangeValue, null, null, false)}`,
      );
    }

    return Promise.resolve();
  };
