export const JOB_FUNCTIONS = {
  artistic: {
    value: 'Artistic',
    subItems: [
      'Digital Content Development',
      'Documentation / Technical Writing',
      'Journalism',
      'Translation / Interpretation',
      'Graphic Artist',
      'Music',
      'Product Design',
      'Textile / Fashion Design',
      'UX / UI Design',
      'Visual / Brand Design',
      'Writing / Editing',
      'Other Artistic',
    ],
  },
  education_training: {
    value: 'Education / Training',
    subItems: [
      'Classroom Teaching',
      'Continuing / Adult',
      'Corporate Development and Training',
      'Early Childhood Care & Development',
      'Elementary School',
      'Fitness & Sports Training / Instruction',
      'Junior / High School',
      'School / College Administration',
      'Software / Web Training',
      'Special Education',
      'University',
      'Other Education / Training',
    ],
  },
  engineering: {
    value: 'Engineering',
    subItems: [
      'Aeronautic / Avionic Engineering',
      'Mechanical',
      'Bio-Engineering',
      'CAD / Drafting',
      'Chemical',
      'Civil & Structural Engineering',
      'Electrical',
      'Energy / Nuclear Engineering',
      'Engineering Design',
      'Environmental and Geological Engineering',
      'Hardware',
      'Industrial / Manufacturing',
      'Naval Architecture / Marine',
      'Process',
      'RF / Wireless',
      'Structural',
      'Systems',
      'Other Engineering',
    ],
  },
  executive: {
    value: 'Executive',
  },
  finance_accounting: {
    value: 'Finance / Accounting',
    subItems: [
      'Accountant',
      'Actuary',
      'Analyst',
      'Accounts Payable / Receivable',
      'Bookkeeping',
      'Budgeting / Cost Control',
      'Controller',
      'Cash Management / Portfolios',
      'Claims Review and Adjusting',
      'Credit / Lending / Collections',
      'Corporate Accounting',
      'Corporate Finance',
      'Financial Analysis / Research/Reporting',
      'Financial Control',
      'Financial Planning / Advising',
      'Financial Products Sales / Brokerage',
      'Fund Accounting',
      'Internal Audit',
      'Investment Management',
      'Mergers & Acquisitions',
      'Pension / Retirement Plan',
      'Purchasing',
      'Policy Underwriting',
      'Risk Management',
      'Securities Analysis / Research',
      'Taxes',
      'Other Finance / Accounting',
    ],
  },
  general_management: {
    value: 'General Management',
  },
  generalist: {
    value: 'Generalist',
  },
  HR_management: {
    value: 'HR Management',
    subItems: [
      'Benefits / Compensation Planning',
      'Employee Relations / Org Development',
      'Generalist',
      'HR Information Systems',
      'Recruiting',
      'Training / E-Learning',
      'Other HR',
    ],
  },
  information_technology: {
    value: 'Information Technology',
    subItems: [
      'Artificial Intelligence',
      'AR / VR Development',
      'Blockchain Development',
      'Business Analysis',
      'Business Intelligence',
      'Computer Network Security',
      'Database Administration',
      'Database & Big Data Technologies',
      'Data Science',
      'Desktop Development',
      'Desktop Service & Support',
      'DevOps',
      'Digital Commerce',
      'Enterprise Software Implementation',
      'Facilities Management',
      'Game Development',
      'IT Project Management',
      'Mainframe',
      'MIS Management',
      'Mobile App Development',
      'Network Administration',
      'Network Engineering',
      'Product Management',
      'Programming',
      'Software Development',
      'System Analysis / Design',
      'System Development',
      'System Integration',
      'System Support',
      'Quality Assurance',
      'Sales Engineering',
      'Security Engineering',
      'Web Development',
      'Other IT',
    ],
  },
  legal: {
    value: 'Legal',
    subItems: [
      'Attorney',
      'Contracts Administration',
      'Labor & Employment Law',
      'Paralegal',
      'Patent / IP Law',
      'Real Estate Law',
      'Regulatory / Compliance Law',
      'Tax Law',
      'Other Legal',
    ],
  },
  manufacturing: {
    value: 'Manufacturing',
    subItems: [
      'Automation',
      'Plant Management',
      'Product Development',
      'Production',
      'Productivity',
      'Quality',
      'Other Manufacturing',
    ],
  },
  materials_management: {
    value: 'Materials Management',
    subItems: [
      'Distribution / Traffic',
      'Materials Planning',
      'Packaging',
      'Purchasing / Inventory / Buyers',
      'Supply Chain',
      'Other Materials Management',
    ],
  },
  medical_health: {
    value: 'Medical / Health',
    subItems: [
      'Administration',
      'Allied',
      'Healthcare IT',
      'HIM / Coder',
      'Lab',
      'Maintenance',
      'Nurse',
      'Nurse Leadership',
      'Office & Clerical',
      'Pharmacy',
      'Physician',
      'Technician',
      'Therapy',
      'Other Medical / Health',
    ],
  },
  office_clerical_administrative: {
    value: 'Office / Clerical / Administrative',
  },
  research_development: {
    value: 'Research & Development',
  },
  sales_marketing: {
    value: 'Sales / Marketing',
    subItems: [
      'Advertising',
      'Account Management (Commissioned)',
      'Analytics',
      'Brand Marketing',
      'Business Development / New Accounts',
      'Customer Service',
      'Direct Marketing',
      'Field Sales',
      'Financial Products Sales / Brokerage',
      'Fundraising',
      'Marketing Management',
      'Media and Advertising Sales',
      'Merchandise Planning and Buying',
      'Product / Market Research',
      'Public Relations',
      'Production',
      'Retail / Counter Sales and Cashier',
      'Sales Support / Assistance',
      'Sales & Sales Management',
      'Store / Branch Management',
      'Strategy / Business Development',
      'Technical Presales Support & Technical Sales',
      'Telesales',
      'Travel Agent / Ticket Sales',
      'Visual / Display Merchandising',
      'Wholesale / Reselling Sales',
      'Other Sales / Marketing',
    ],
  },
  miscellaneous: {
    value: 'Miscellaneous',
    subItems: [
      'Architect / Building Contractors',
      'Environmentalist',
      'Hotel Management',
      'Management Consulting',
      'Security Personnel',
      'Other',
    ],
  },
};

export const JOB_FUNCTIONS_MAPPED = {
  artistic: 'Artistic',
  education_training: 'Education / Training',
  engineering: 'Engineering',
  executive: 'Executive',
  finance_accounting: 'Finance / Accounting',
  general_management: 'General Management',
  generalist: 'Generalist',
  hr_management: 'HR Management',
  information_technology: 'Information Technology',
  legal: 'Legal',
  manufacturing: 'Manufacturing',
  materials_management: 'Materials Management',
  medical_health: 'Medical / Health',
  office_clerical_administrative: 'Office / Clerical / Administrative',
  research_development: 'Research & Development',
  sales_marketing: 'Sales / Marketing',
  miscellaneous: 'Miscellaneous',
};
