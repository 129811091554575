import AppCard from '@wieldy/components/AppCard';
import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Button, Col} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import RequisitionModal from 'app/modals/RequisitionModal';
import {useGetRequisition} from 'app/services/requisitionService';
import ClientCard from 'app/shared/client/ClientCard';
import {RequisitionCard} from 'app/shared/requisitions';
import {SupplierCard} from 'app/shared/supplier';
import {
  setPlacementCandidate,
  setPlacementDetails,
  setPlacementId,
  setPlacementJobDetails,
  setPlacementRequisition,
} from 'app/store/features/placement/placementSlice';
import {getFullName} from 'app/utils/helpers/DataFormatHelper';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';

import SpendingSummary from './SpendingSummary';
import Summary from './Summary';
import Candidate from './Summary/Candidate';
import JobDetails from './Summary/JobDetails';

const MainContent = ({placement}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {isSupplier, isClient, isMarketplace} = useAccountType();
  const {id} = useParams();
  const {isLoading, data} = useGetRequisition(placement.requisition.id, {
    include: [
      'requisition_common_inquiries',
      'requisition_common_submissions',
      'requisition_common_placements',
      'skill_tags',
    ],
    expand: ['hiring_manager'],
    ...(isClient && {target_supplier: placement.supplier.id}),
  });
  const requisition = useMemo(() => new RequisitionModal(data), [data]);

  const handleEdit = () => {
    const {requisition, applicant, candidate} = placement;

    dispatch(setPlacementId(id));
    dispatch(setPlacementRequisition(requisition));
    dispatch(
      setPlacementCandidate({
        name: getFullName(applicant),
        id: candidate.id,
      }),
    );

    dispatch(
      setPlacementDetails({
        placement_supplier_id: placement.supplier.id,
        start_date: placement.start_date
          ? moment(placement.start_date)
          : undefined,
        hiring_manager: placement.hiring_manager,
        account_manager: placement.account_manager,
        resource_manager: placement.resource_manager,
        base_salary: placement.base_salary,
        billing_rate: placement.billing_rate,
        recruitment_fee: placement?.recruitment_fee,
        recruitment_fee_structure: placement?.recruitment_fee_structure,
        guarantee_period: placement.guarantee_period,
        guarantee_period_action: placement?.guarantee_period_action,
      }),
    );
    dispatch(
      setPlacementJobDetails({
        job_worksite: placement.job_worksite,
        job_worksite_location: placement.job_worksite_location,
        job_country: placement.job_country,
        charge_tax: placement.charge_tax,
        city: placement.city,
        united_states: placement.united_states,
        part_time: placement.part_time,
        job_status: placement.job_status,
        job_travel_requirements: placement.job_travel_requirements,
        job_flsa_eligible: placement.job_flsa_eligible,
        job_payment_term: placement.job_payment_term,
        tax_engagement: placement.tax_engagement,
        tax_labor: placement.tax_labor,
        tax_expenses: placement.tax_expenses,
        tax_country: placement.tax_country,
        tax_state: placement.tax_state,
        tax_city: placement.tax_city,
      }),
    );
    navigate('/placements/manage-placement');
  };

  return (
    <AppGridContainer>
      <Col xs={24} lg={15} xxl={16}>
        <AppGridContainer>
          <Col xs={24}>
            <AppCard
              title='Summary'
              headStyle={{borderBottom: 0}}
              bodyStyle={{paddingTop: 8}}>
              <Summary placement={placement} />
              <JobDetails placement={placement} />
              <Candidate
                applicant={placement.applicant}
                candidateId={placement.candidate.id}
              />
              {isMarketplace && isClient && (
                <Button
                  className='mt-2'
                  shape='round'
                  onClick={handleEdit}
                  type='primary'>
                  Manage Placement
                </Button>
              )}
            </AppCard>
          </Col>
          <Col xs={24} id='spending-summary'>
            <SpendingSummary placement={placement} />
          </Col>
        </AppGridContainer>
      </Col>
      <Col xs={24} lg={9} xxl={8}>
        <AppGridContainer>
          <Col xs={24} sm={24} md={12} lg={24} id='client'>
            {isSupplier ? (
              <ClientCard client={placement.client} />
            ) : (
              <SupplierCard supplier={placement.supplier} />
            )}
          </Col>
          <Col xs={24} sm={24} md={12} lg={24} id='requisition'>
            <RequisitionCard isLoading={isLoading} requisition={requisition} />
          </Col>
        </AppGridContainer>
      </Col>
    </AppGridContainer>
  );
};

MainContent.propTypes = {
  placement: PropTypes.object,
  notes: PropTypes.object,
};

export default MainContent;
