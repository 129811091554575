import {JOB_FUNCTIONS_MAPPED} from '@register-app/constants/job-functions';
import AppLabels from '@wieldy/components/AppLabels';
import {
  ACCEPT_CANDIDATES_OPTIONS,
  WORK_TYPE_KEYS,
} from 'app/constants/work-types';
import PropTypes from 'prop-types';
import React from 'react';

import MultipleValuePreview from '../../components/MultipleValuePreview';

const ItemInfo = ({label, content}) => {
  return (
    <tr>
      <td width='30%' className={'text-secondary'}>
        {label}
      </td>
      <td>{content}</td>
    </tr>
  );
};

ItemInfo.propTypes = {
  label: PropTypes.string,
  content: PropTypes.node,
};

const RequisitionCardContent = ({requisition}) => {
  const {workType, type, contractType} = requisition;
  const typeValue =
    type !== WORK_TYPE_KEYS.contract
      ? workType
      : `${workType} ${contractType ? `(${contractType})` : ''}`;
  return (
    <table className='table' style={{margin: '0 -8px 16px'}}>
      <tbody>
        <ItemInfo
          label='Filled/Openings'
          content={`${requisition?.filled}/${requisition?.openings}`}
        />
        <ItemInfo
          label='Job Function'
          content={JOB_FUNCTIONS_MAPPED[requisition.job_function]}
        />
        <ItemInfo
          label='Skills'
          content={
            requisition?.skillTags && (
              <AppLabels
                items={requisition.skillTags.map((item) => item?.name)}
                visible={1}
              />
            )
          }
        />

        <ItemInfo label='Type' content={typeValue} />
        <ItemInfo
          label='Open to'
          content={
            <MultipleValuePreview
              type={requisition.accept_candidates_from}
              source={ACCEPT_CANDIDATES_OPTIONS}
            />
          }
        />
        <ItemInfo label='Part Time' content={requisition?.partTime} />

        {requisition.isDirectHire || requisition.isTemporary ? (
          <ItemInfo label={'Job Start Date'} content={requisition?.startDate} />
        ) : (
          <ItemInfo
            label='Start/End Dates'
            content={`${requisition?.startDate} - ${requisition?.endDate}`}
          />
        )}

        <ItemInfo label='Work Model' content={requisition?.jobWorkSite} />
        <ItemInfo
          label='Job Location'
          content={
            requisition?.jobLocation ||
            (requisition.jobCountry
              ? requisition.jobCountry.join(', ')
              : 'Any country')
          }
        />
      </tbody>
    </table>
  );
};

RequisitionCardContent.propTypes = {
  requisition: PropTypes.object,
};

export default RequisitionCardContent;
