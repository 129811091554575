import moment from 'moment';
import {useMemo} from 'react';

import {DATE_FORMATS} from '../config/app';
import {RECRUITMENT_FEE_STRUCTURE_KEYS} from '../constants';
import {calcRecruitmentFee} from '../utils/helpers/CalcRecruitmentFee';
import {formatSecondsToDate} from '../utils/helpers/DataFormatHelper';

export const useCalcFee = (requisition) => {
  const {
    recruitment_fee_structure,
    base_salary,
    base_salary_min,
    base_salary_max,
    billing_rate,
    billing_rate_min,
    billing_rate_max,
    recruitment_fee,
    isTemporary,
    isContract,
    isC2C,
    end_date,
    start_date,
  } = requisition;

  const isFeeContract = isContract && !isC2C;

  const isFixed =
    recruitment_fee_structure === RECRUITMENT_FEE_STRUCTURE_KEYS.fixed_fee;

  const startDate = moment(formatSecondsToDate(start_date), DATE_FORMATS.md);
  const endDate = end_date
    ? moment(formatSecondsToDate(end_date), DATE_FORMATS.md)
    : null;
  const diff =
    isFeeContract && endDate ? endDate.diff(startDate, 'days') || 1 : null;

  const fee = useMemo(() => {
    if (isTemporary || diff) {
      return calcRecruitmentFee({
        isFixed,
        isTemporary,
        diff,
        base: billing_rate,
        min: billing_rate_min,
        max: billing_rate_max,
        fee: recruitment_fee,
      });
    }
    return calcRecruitmentFee({
      isFixed,
      base: base_salary,
      min: base_salary_min,
      max: base_salary_max,
      fee: recruitment_fee,
    });
  }, [
    base_salary,
    base_salary_max,
    base_salary_min,
    billing_rate,
    billing_rate_max,
    billing_rate_min,
    diff,
    isFixed,
    isTemporary,
    recruitment_fee,
  ]);

  return {isFixed, fee};
};
