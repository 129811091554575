import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React from 'react';

import {WORK_TYPES} from '../../../constants';
import {
  CONTRACT_TYPES,
  NEW_WORK_TYPES,
  WORK_TYPE_KEYS,
} from '../../../constants/work-types';
import {useCalcFee} from '../../../hooks/useCalcFee';
import {formatBillingRateRage} from '../../../utils/helpers/DataFormatHelper';

const ItemInfo = ({label, content}) => {
  return (
    <tr>
      <td width='40%' className={'text-secondary'}>
        {label}
      </td>
      <td>{content}</td>
    </tr>
  );
};

ItemInfo.propTypes = {
  label: PropTypes.string,
  content: PropTypes.node,
};

const PlacementCardContent = ({placement}) => {
  const isContract = placement.requisition.type === WORK_TYPE_KEYS.contract;
  const isTemporary = placement.requisition.type === WORK_TYPE_KEYS.temporary;

  const isBilling = isContract || isTemporary;

  const startDate = moment(placement?.start_date).unix();
  const endDate = placement?.end_date
    ? moment(placement?.end_date).unix()
    : null;

  const {fee} = useCalcFee({
    ...placement,
    isTemporary,
    isContract,
    isC2C: false,
    start_date: startDate,
    end_date: endDate,
  });

  return (
    <table className='table' style={{margin: '0 -8px 24px'}}>
      <tbody>
        <ItemInfo
          label='Type'
          content={`${
            {...WORK_TYPES, ...NEW_WORK_TYPES}[placement?.requisition?.type]
          } ${
            placement?.requisition?.contract_type
              ? `(${CONTRACT_TYPES[placement?.requisition?.contract_type]})`
              : ''
          }`}
        />
        <ItemInfo label='Job Start Date' content={placement?.start_date} />
        <ItemInfo
          label='Guarantee End Date'
          content={placement?.guarantee_end_date}
        />
        <ItemInfo
          label={isBilling ? 'Hourly Rate' : 'Base Salary'}
          content={formatBillingRateRage(
            isBilling ? placement.billing_rate : placement?.base_salary,
            null,
            null,
          )}
        />
        <ItemInfo label='Recruitment Fee' content={fee} />
      </tbody>
    </table>
  );
};

PlacementCardContent.propTypes = {
  placement: PropTypes.object,
};

export default PlacementCardContent;
