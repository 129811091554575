import AppTag from '@wieldy/components/AppTag';
import AppUserProfile from '@wieldy/components/AppUserProfile';
import {Typography} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import {RiMapPinLine, RiProfileLine} from 'react-icons/ri';
import {useNavigate} from 'react-router-dom';

import {BASE_SALARY_PERIOD} from '../../../constants';
import {useAccountType} from '../../../hooks/useAccountType';
import {formatBillingRateRage} from '../../../utils/helpers/DataFormatHelper';

const CandidateHeader = ({candidate}) => {
  const {isSupplier, isClient} = useAccountType();

  const navigate = useNavigate();
  const isAvailableCandidate =
    isSupplier &&
    (!!candidate.resume_key ||
      candidate.employment_history?.length ||
      candidate.skill_tags?.data?.length);
  const onSupplierClick = () => {
    navigate(`/resources/${candidate?.id}/view-resource`);
  };

  return (
    <React.Fragment>
      <div
        className={
          'd-flex flex-wrap justify-content-between align-items-end mb-6'
        }>
        <AppUserProfile
          isCompany={false}
          avatarUrl={
            candidate?._orignalProps?.user?.avatarUrl ||
            candidate?.avatar ||
            candidate?.resource?.avatar_url
          }
          renderTitle={
            <React.Fragment>
              {candidate?.name}
              <AppTag
                shape={'circle'}
                color={isAvailableCandidate || isClient ? 'success' : 'default'}
                label={
                  isAvailableCandidate || isClient
                    ? candidate?.availabilityName
                    : 'incomplete'
                }
                clickable
                className={'tracking-normal ml-2'}
              />
            </React.Fragment>
          }
          titleProps={{onClick: onSupplierClick}}
          items={[
            {
              icon: <RiProfileLine fontSize={16} />,
              content: candidate?.title,
            },
            {
              icon: <RiMapPinLine fontSize={16} />,
              content: candidate.location,
            },
          ]}
          className={'flex-fill align-items-center'}
        />
        <div className={'page-header-info-extra'}>
          <div className='list-info'>
            <div className='list-info-item'>
              <Typography.Text className='info-count'>
                {candidate?.years_experience}
              </Typography.Text>
              <Typography.Text type={'secondary'}>
                Years of Experience
              </Typography.Text>
            </div>
            {!candidate.type && (
              <div className='list-info-item'>
                <Typography.Text className='info-count'>
                  {candidate?.engagements}
                </Typography.Text>
                <Typography.Text type={'secondary'}>
                  Past Assignments
                </Typography.Text>
              </div>
            )}

            <div className='list-info-item'>
              <Typography.Text className='info-count'>
                {candidate.isPermanent
                  ? formatBillingRateRage(
                      candidate.base_salary,
                      candidate.base_salary_min,
                      candidate.base_salary_max,
                      false,
                    )
                  : candidate?.billingRate}
                {}
              </Typography.Text>
              <Typography.Text type={'secondary'}>
                {candidate.isPermanent
                  ? `${
                      BASE_SALARY_PERIOD[candidate.base_salary_period]
                    } Base Salary`
                  : `${candidate?.billing_rate_period} Billing Rate`}
              </Typography.Text>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

CandidateHeader.propTypes = {
  candidate: PropTypes.object,
};

export default CandidateHeader;
