import AppDynamicTabs from '@wieldy/components/AppTabs/AppDynamicTabs';
import AppPageContainer from '@wieldy/components/pageComponents/AppPageContainer';
import AppPageHeader from '@wieldy/components/pageComponents/AppPageHeader';
import {CATEGORIES} from 'app/constants/index.js';
import All from 'app/pages/main/clients/Clients/MainContent/All.js';
import Browse from 'app/pages/main/clients/Clients/MainContent/Browse.js';
import PageQueryProvider from 'app/providers/PageQueryProvider/PageQueryProviderNew';
import {filterConfig} from 'app/shared/client/FilterClients';
import React, {useMemo} from 'react';
import {useParams} from 'react-router-dom';

import {pageQueries, primaryTabs} from './constants';

const Clients = () => {
  const {category} = useParams();

  const isAll = useMemo(() => category === CATEGORIES.ALL, [category]);

  const activeKey = React.useMemo(() => {
    return category ?? CATEGORIES.ALL;
  }, [category]);

  const currentPageParams = pageQueries[category];
  const {title} = currentPageParams;

  return (
    <PageQueryProvider
      category={category}
      pageParams={pageQueries}
      filterConfig={filterConfig}>
      <AppPageContainer
        header={
          <AppPageHeader
            className={'has-tabs-secondary'}
            title={title}
            category='Suppliers'
            tabs={
              <AppDynamicTabs tabsData={primaryTabs} activeKey={activeKey} />
            }
            //todo UNCOMMENT SOON
            // extra={
            //   <AppDynamicTabs
            //     className='tabs-secondary'
            //     tabsData={secondaryTabs}
            //     activeKey={activeKey}
            //   />
            // }
          />
        }>
        {isAll ? <All /> : <Browse />}
      </AppPageContainer>
    </PageQueryProvider>
  );
};

export default Clients;
