export default {
  contract: 'Contract',
  direct_hire: 'Direct hire',
};

export const NEW_WORK_TYPES = {
  full_time: 'Full-time',
  part_time: 'Part-time',
  temporary: 'Temporary',
  contract: 'Contract',
};

export const WORK_TYPE_KEYS = {
  contract: 'contract',
  full_time: 'full_time',
  part_time: 'part_time',
  temporary: 'temporary',
};

export const CONSTRACT_OPTIONS_KEYS = {
  talent_vendors: 'talent_vendors',
  staffing_agencies: 'staffing_agencies',
};

export const C2P_OPTIONS = {
  staffing_agencies: 'Staffing agencies',
  talent_vendors: 'Talent vendors',
};

export const FULL_TIME_OPTIONS = {
  recruitment_agencies: 'Recruitment agencies',
  direct_sourcing: 'Direct sourcing',
};
export const TEMPORARY_OPTIONS = {
  staffing_agencies: 'Staffing agencies',
  direct_sourcing: 'Direct sourcing',
};

export const INDEPENDENT_AND_W2_OPTIONS = {
  staffing_agencies: 'Staffing agencies',
};

export const ACCEPT_CANDIDATES_OPTIONS = {
  recruitment_agencies: 'Recruitment agencies',
  direct_sourcing: 'Direct sourcing',
  talent_vendors: 'Talent vendors',
  staffing_agencies: 'Staffing agencies',
};

export const CONTRACT_TYPES = {
  c2c: 'Corp to corp',
  independent: 'Independent',
  w2: 'W-2',
};

export const ACCEPT_CANDIDATES_FROM_MAP = {
  c2c: C2P_OPTIONS,
  independent: INDEPENDENT_AND_W2_OPTIONS,
  w2: INDEPENDENT_AND_W2_OPTIONS,
  temporary: TEMPORARY_OPTIONS,
  full_time: FULL_TIME_OPTIONS,
  part_time: FULL_TIME_OPTIONS,
};
