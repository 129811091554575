import {getStepItems} from '@register-app/components/steps/steps';
import {getRecruitingServices} from '@register-app/utils/get-recruiting-services';
import {getRegionArgs} from '@register-app/utils/get-region-args';
import {notification} from 'antd';
import Steps from 'app/shared/Steps';
import {useCallback, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {
  resetRequestHeader,
  setRequestHeader,
} from '../../../../../config/axios';
import {supplierTypes as SUPPLIER_TYPES} from '../../../../../constants/supplier-types';
import {
  useCreateSupplier,
  useSentSupplierInvite,
} from '../../../../../services/supplierService';

const filterValues = (value) => {
  return Object.fromEntries(
    Object.entries(value).filter(([, val]) => val.value),
  );
};

const MainContent = () => {
  const navigate = useNavigate();
  const [state, setState] = useState(null);

  const mutationCreateSupplier = useCreateSupplier();
  const mutationInvite = useSentSupplierInvite();

  const handleSetState = useCallback((payload) => {
    setState((prevState) => {
      return {
        ...prevState,
        ...payload,
      };
    });
  }, []);

  const handleOnFinish = () => {
    const {
      account_manager,
      account_owner,
      billing_address,
      primary_address,
      company_info,
      diversity_information,
      service_regions,
      marketplace_fees,
    } = state;

    const isAgency =
      company_info?.type?.includes(SUPPLIER_TYPES.recruitingAgency) ||
      company_info?.type?.includes(SUPPLIER_TYPES.staffingAgency);

    const schemaArgs = getRegionArgs(company_info?.type);

    const {placement_type, company_recruiting_services, ...data} = company_info;

    const {city, country, us_state, ...primaryAddress} = primary_address;

    const {
      placement_contingent_placement_fee,
      placement_retained_fee,
      placement_temporary_hires,
      placement_temp_to_perm,
    } = placement_type;

    const payloadData = {
      name: company_info.name,
      company_dba_name: company_info.company_dba_name,
      company_size: company_info.company_size,
      company_type: company_info.company_type,
      status: company_info.status,
      website: company_info.website,
      years_in_business: data.years_in_business,
      tax_jurisdiction: data.tax_jurisdiction || null,
      ...(isAgency ? company_recruiting_services : getRecruitingServices()),
      placement_contingent_placement_fee: isAgency
        ? placement_contingent_placement_fee
        : null,
      placement_retained_fee: isAgency ? placement_retained_fee : null,
      placement_temporary_hires: isAgency ? placement_temporary_hires : null,
      placement_temp_to_perm: isAgency ? placement_temp_to_perm : null,
      ats_used_by_company: isAgency ? data.ats_used_by_company : null,
      number_of_recruiters: isAgency ? data.number_of_recruiters : null,
      linkedin: company_info.linkedin,
      ...diversity_information,

      about: company_info.about,
      type: company_info.type,
      ...service_regions,
      specialization_industry_focuses: filterValues(
        service_regions?.specialization_industry_focuses,
      ),
      specialization_job_functions: filterValues(
        service_regions.specialization_job_functions,
      ),
      specialization_service_focuses: schemaArgs.isService
        ? filterValues(service_regions.specialization_service_focuses)
        : null,
      account_manager: account_manager?.id,
      primary_address: primaryAddress,
      city,
      country,
      us_state,
      billing_address: {
        ...billing_address,
        company_legal_name: company_info.name,
        phone_number: billing_address?.phone_number?.slice(1),
      },
      ...(marketplace_fees.length && {msp_fee: marketplace_fees}),
    };

    mutationCreateSupplier.mutate(payloadData, {
      onSuccess: async (res) => {
        await setRequestHeader('sourcer-supplier', res.data.id);

        await mutationInvite.mutate(
          {
            account_owner,
            phone_number: account_owner.phone_number
              ? account_owner.phone_number.slice(1)
              : null,
          },
          {
            onError: (error) => {
              notification.error({message: error.message});
            },
            onSettled: () => {
              navigate('/accounts/suppliers');
              resetRequestHeader('sourcer-supplier');
            },
          },
        );
      },
    });
  };

  return (
    <div id='supplier-registration'>
      <Steps
        items={getStepItems(handleOnFinish)}
        current={0}
        bindProps={{state, setState: handleSetState}}
      />
    </div>
  );
};

export default MainContent;
