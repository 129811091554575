import {CATEGORIES} from 'app/constants';

export const statuses = [
  {status: CATEGORIES.NEW, sort: ['-created_at']},
  {status: CATEGORIES.IN_PROGRESS, sort: ['-status_changed_at']},
  {status: CATEGORIES.CLOSED, sort: ['-status_changed_at']},
];

export const queryParamsList = {
  expand: ['requisition', 'client', 'supplier'],
  include: ['total_count', 'candidates'],
};

export const pageParams = {
  all: {
    queryParams: {
      ...queryParamsList,
    },
    title: 'All Candidates',
  },
  new: {
    queryParams: {
      ...queryParamsList,
      status: 'new',
    },
    title: 'New Submissions',
    label: 'Created',
    rowTitle: 'New Submissions',
    empty: 'No New Submissions',
  },
  in_progress: {
    queryParams: {
      ...queryParamsList,
      status: 'in_progress',
    },
    title: 'In Progress Submissions',
    label: 'Status changed',
    rowTitle: 'In Progress Submissions',
    empty: 'No In Progress Submissions',
  },
  closed: {
    queryParams: {
      ...queryParamsList,
      status: 'closed',
    },
    title: 'Closed Submissions',
    label: 'Closed',
    rowTitle: 'Closed Submissions',
    empty: 'No Closed Submissions',
  },
};

export const tabsData = [
  {key: 'all', title: 'All', route: '/submissions/all'},
  {key: 'new', title: 'New', route: '/submissions/new'},
  {key: 'in_progress', title: 'In Progress', route: '/submissions/in_progress'},
  {key: 'closed', title: 'Closed', route: '/submissions/closed'},
];

const defaultViewTabs = (isSupplier) => [
  {key: 'submission', title: 'Submission'},
  {key: 'client', title: isSupplier ? 'Client' : 'Supplier'},
  {key: 'requisition', title: 'Requisition'},
];

export const getViewTabsData = (isNotes, isSupplier, isBoard) => {
  const tabs = defaultViewTabs(isSupplier);

  if (isBoard) {
    tabs.splice(1, 1);
  }

  if (!isNotes) {
    return tabs;
  } else {
    return [...tabs, {key: 'notes', title: 'Notes'}];
  }
};

export const mapFilterToCategory = {
  new: [
    {label: 'Created date (newest first)', value: '-created_at'},
    {label: 'Created date (oldest first)', value: '+created_at'},
  ],
  in_progress: [
    {
      label: 'Status changed date (newest first)',
      value: '-status_changed_at',
    },
    {
      label: 'Status changed date (oldest first)',
      value: '+status_changed_at',
    },
  ],
  closed: [
    {label: 'Closed (newest first)', value: '-status_changed_at'},
    {label: 'Closed date (oldest first)', value: '+status_changed_at'},
  ],
};

export const candidateStatus = [
  {
    value: 'interested',
    label: 'Interested',
  },
  {
    value: 'not_interested',
    label: 'Not Interested',
  },
  {
    value: 'to_be_interviewed',
    label: 'To Be Interviewed',
  },
  {
    value: 'interviewed_success',
    label: 'Interviewed - Success',
  },
  {
    value: 'interviewed_fail',
    label: 'Interviewed - Fail',
  },
  {
    value: 'interviewed_on_hold',
    label: 'Interviewed - On Hold',
  },
  {
    value: 'to_be_engaged',
    label: 'To Be Engaged',
  },
];
